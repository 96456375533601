import {FETCH_BLOG} from '../actions';

const defaultState = [];

const blog = (state = defaultState, action) => {

    switch (action.type) {
        case FETCH_BLOG:
            if (action.payload.items) {
                return action.payload.items;
            }
            return state;

        default:
            return state;
    }
};

export default blog;
