import React, {useEffect, useState} from 'react';
import {Element} from 'react-scroll';

import VillenListItem from './item';

const VillenList = (props) => {
    const [merkliste, setMerkliste] = useState(props.merkliste);
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [villen, setVillen] = useState(props.villen);
    useEffect(() => {
        setVillen(props.villen);
    }, [props.villen]);

    return <><Element name="villenListScrollElement"></Element>{
        villen.length > 0 && villen.map((item, index) => {
            return <VillenListItem item={item} key={index} merkliste={merkliste}
                                   toggleMerkliste={props.toggleMerkliste} language={language}/>;
        })
    }</>;
};

VillenList.defaultProps = {
    villen: [],
    merkliste: {},
    language: '',
    toggleMerkliste: () => {
    },
};

export default VillenList;
