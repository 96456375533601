import React, {useEffect, useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import {fonts, fontSizes, lineHeights} from '../../tools/styles';

const useStyles = createUseStyles({
    link: {
        fontStyle: 'italic',
        fontFamily: fonts.primary,
        fontSize: fontSizes.boxed,
        lineHeight: lineHeights.boxed,
        letterSpacing: 0,
        color: 'white',
        '&:hover': {
            color: 'white',
            opacity: 0.8,
            textDecoration: 'none',
        },
    },
});

const BottomNav = (props) => {
    const {t} = useTranslation(['general', 'villas']);
    const classes = useStyles();

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <Nav className="justify-content-center">
        <Nav.Item>
            <LinkContainer to={`/${language}/imprint`}>
                <Nav.Link className={classes.link}>{t('imprint')}</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        <Nav.Item>
            <LinkContainer to={`/${language}/data-privacy`}>
                <Nav.Link className={classes.link}>{t('dataPrivacy')}</Nav.Link>
            </LinkContainer>
        </Nav.Item>
    </Nav>;
};

BottomNav.defaultProps = {
    language: '',
};

export default BottomNav;
