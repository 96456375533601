const smMin = 576;
const mdMin = 768;
const lgMin = 992;
const xlMin = 1200;

export const breakpoints = {
    xsMax: smMin - 1,

    smMin: smMin,
    smMax: mdMin - 1,

    mdMin: mdMin,
    mdMax: lgMin - 1,

    lgMin: lgMin,
    lgMax: xlMin - 1,

    xlMin: xlMin,
};

export const colors = {
    primary: '#0D1822',
    secondary: '#E65759',
    muted: '#383838',
    vibrate: '#19B5C2',
};

export const fonts = {
    primary: '"Playfair Display"',
    secondary: 'Poppins',
};

export const fontSizes = {
    unboxed: 22,
    unboxedMd: 18,
    unboxedSm: 16,
    boxed: 18,
    boxedMd: 16,
    boxedSm: 14,
};

export const lineHeights = {
    unboxed: '32px',
    unboxedMd: '24px',
    unboxedSm: '22px',
    boxed: '24px',
    boxedMd: '20px',
    boxedSm: '18px',
};

export const tools = {
    boxShadow: '0px 3px 6px #00000029',
    boxShadowHover: '0px 6px 9px #00000029',
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
};

const buttonDefaults = {
    boxShadow: tools.boxShadow,
    border: '2px solid',
    borderColor: colors.secondary,
    borderRadius: tools.borderRadius,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '27px',
    fontFamily: fonts.secondary,
    letterSpacing: 0,
    padding: '7px 40px',
    overflow: 'hidden',
    backgroundPosition: '0 65px',
    backgroundRepeat: 'repeat-x',
    transition: 'background-position 1s ease,color 1s ease',
    [`@media (min-width: ${breakpoints.smMin}px) and (max-width: ${breakpoints.smMax}px)`]: {
        padding: '5px 30px',
        fontSize: 16,
    },
    [`@media (max-width: ${breakpoints.xsMax}px)`]: {
        padding: '4px 20px',
        fontSize: 15,
    },
};

export const buttons = {
    secondary: {
        ...buttonDefaults,
        color: colors.secondary,
        backgroundColor: 'white',
        backgroundImage: 'url(/images/wave-button.svg)',
        '&:hover': {
            color: 'white',
            backgroundPosition: '0 -20px',
        },
    },
    primary: {
        ...buttonDefaults,
        color: 'white',
        backgroundImage: 'url(/images/wave-button-white.svg)',
        backgroundColor: colors.secondary,
        '&:hover': {
            color: colors.secondary,
            backgroundPosition: '0 -20px',
        },
    },
};

export const fontSizeMixins = {
    unboxed: {
        fontSize: fontSizes.unboxed,
        lineHeight: lineHeights.unboxed,
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: fontSizes.unboxedMd,
            lineHeight: lineHeights.unboxedMd,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: fontSizes.unboxedSm,
            lineHeight: lineHeights.unboxedSm,
        },
    },
    boxed: {
        fontSize: fontSizes.boxed,
        lineHeight: lineHeights.boxed,
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: fontSizes.boxedMd,
            lineHeight: lineHeights.boxedMd,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: fontSizes.boxedSm,
            lineHeight: lineHeights.boxedSm,
        },
    }
};

export const mixins = {
    h1: {
        fontFamily: fonts.secondary,
        fontWeight: 'bold',
        letterSpacing: 0,
        color: colors.primary,
        margin: 0,
        padding: 0,
        lineHeight: 1.5,
        fontSize: 62,
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: 42,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: 32,
        },
    },
    h3: {
        fontWeight: 'bold',
        fontFamily: fonts.secondary,
        fontSize: 40,
        letterSpacing: 0,
        color: colors.primary,
        margin: 0,
        padding: 0,
        lineHeight: 1.5,
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: 32,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: 24,
        },
    },
    h4: {
        fontWeight: 'bold',
        fontFamily: fonts.secondary,
        fontSize: 28,
        letterSpacing: 0,
        color: colors.primary,
        margin: 0,
        padding: 0,
        lineHeight: 1.5,
        '&>a': {
            transition: tools.transition,
            color: colors.secondary,
            '&:hover': {
                opacity: 0.8,
                textDecoration: 'none',
            },
        },
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: 24,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: 20,
        },
    },
    h5: {
        fontWeight: 'bold',
        fontFamily: fonts.secondary,
        fontSize: 20,
        letterSpacing: 0,
        color: colors.primary,
        margin: 0,
        padding: 0,
        lineHeight: 1.5,
        '&>a': {
            transition: tools.transition,
            color: colors.secondary,
            '&:hover': {
                opacity: 0.8,
                textDecoration: 'none',
            },
        },
        [`@media (min-width: ${breakpoints.mdMin}px) and (max-width: ${breakpoints.mdMax}px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: 16,
        },
    },
    caption: {
        letterSpacing: 0,
        fontWeight: 'bold',
        fontSize: 18,
        fontFamily: fonts.secondary,
        color: colors.primary,
        margin: 0,
        marginBottom: 10,
        padding: 0,
        lineHeight: 1,
        [`@media (min-width: ${breakpoints.smMin}px) and (max-width: ${breakpoints.smMax}px)`]: {
            fontSize: 16,
        },
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            fontSize: 15,
        },
    },
    unboxed: {
        ...fontSizeMixins.unboxed,
        ...lineHeights.unboxed,
        fontFamily: fonts.primary,
        letterSpacing: 0,
        color: colors.muted,
    },
    p: {
        ...fontSizeMixins.boxed,
        ...lineHeights.boxed,
        fontFamily: fonts.primary,
        letterSpacing: 0,
        color: colors.muted,
        '&>a': {
            transition: tools.transition,
            color: colors.secondary,
            '&:hover': {
                opacity: 0.8,
                textDecoration: 'none',
            },
        },
    },
};
