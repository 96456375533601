import React from 'react';
import {createUseStyles} from 'react-jss';

const Logo = (props) => {
    const useStyles = createUseStyles({
        logo: {
            height: props.h,
            width: props.w,
            maxWidth: '100%',
        },
    });

    const classes = useStyles();
    return <img className={classes.logo} src="/images/logo.png" alt=""/>;
};

Logo.defaultProps = {
    h: 30,
    w: 'auto',
};

export default Logo;
