import React from 'react';
import {useTranslation} from 'react-i18next';

import {BlogCategories} from '../../enums/blogCategories';

const BlogCategoryCaption = (props) => {
    const {t} = useTranslation(['blog']);
    return <span className={props.className}>{t(props.category)}</span>;
};

export default BlogCategoryCaption;
