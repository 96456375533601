import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import {fetchAuthors, fetchBlog} from '../../../actions';
import BackLink from '../../../components/common/BackLink';
import {mixins} from '../../../tools/styles';
import SocialShare from '../../../components/common/SocialShare';
import {getLanguageOfLocation} from '../../../tools/tools';
import TopNavBar from '../../../components/common/TopNavBar';
import HeadSpace from '../../../components/common/HeadSpace';
import HeightSpacer from '../../../components/common/HeightSpacer';
import CustomMarkdown from '../../../components/common/CustomMarkdown';
import Item from '../../Blog/Item';

const useStyles = createUseStyles({
    h3: {
        ...mixins.h3,
    },
    h4: {
        ...mixins.h4,
    },
    caption: {
        ...mixins.caption,
    },
    unboxed: {
        ...mixins.unboxed,
    },
});

const AuthorDetail = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['blog']);

    const [author, setAuthor] = useState({});
    const [latestArticle, setLatestArticle] = useState({});
    const [blog, setBlog] = useState([]);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchAuthors(language);
        props.fetchBlog(language);
    }, [props.location]);

    useEffect(() => {
        props.authors.map((item, index) => {
            if (item.fields.slug === props.match.params.slug) {
                setAuthor(item);
            }
        });
    }, [props.authors]);

    useEffect(() => {
        if (!author.fields) {
            return;
        }
        if (props.blog.length < 1) {
            return;
        }

        const blogItems = [];

        props.blog.map((itm) => {
            if (itm.fields.autor && itm.fields.autor.fields.slug === author.fields.slug) {
                blogItems.push(itm);
            }
        });

        if(blogItems.length > 0) {
            setLatestArticle(blogItems.splice(0,1)[0]);
        }
        setBlog(blogItems);

    }, [props.blog, author]);

    return <>
        <Helmet>
            <title>{author.fields && `${author.fields.name} | Villas of Paradise`}</title>
            {author.fields && <meta name="title" content={`${author.fields.name}`}/>}
            {author.fields &&
            <meta name="description" content={`${author.fields.name}`}/>}
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <Container>
            <Row>
                <Col xl={9}>
                    <Row>
                        <Col xs={6}>
                            <BackLink language={props.language.active}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {author.fields && <div><Row>
                        <Col md={9}>
                            <Row>
                                <Col md={8}>
                                    <h1 className={classes.h3}>{author.fields.name}</h1>
                                    <HeightSpacer/>
                                </Col>
                                <Col md={4}>
                                    <div className={'d-sm-flex d-md-none'}>
                                        <HeightSpacer/>
                                    </div>

                                    <SocialShare language={props.language.active}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomMarkdown className={classes.unboxed} source={author.fields.description}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            {latestArticle.fields && <Item item={latestArticle} isFullHeight={false} language={props.language.active}/>}
                        </Col>
                    </Row>
                    {blog.length > 0 && <div>
                        <br/><br/>
                        <Row>
                            <Col>
                                <h2 className={classes.h4}>
                                    {t('moreArticlesOfAuthor')}
                                </h2>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            {blog.map((item, index) => {
                                return <Col sm={4} key={index} style={{marginBottom: 30}}>
                                    <Item item={item} language={props.language.active}/>
                                </Col>;
                            })}
                        </Row>
                    </div>}
            </div>}
        </Container>;
    </>;
};

const mapStateToProps = (state) => {
    return {
        blog: state.blog,
        authors: state.authors,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthors: (language) => {
            dispatch(fetchAuthors(language));
        },
        fetchBlog: (language) => {
            dispatch(fetchBlog(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorDetail);
