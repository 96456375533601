import Container from 'react-bootstrap/Container';
import React from 'react';
import {Link} from 'react-router-dom';

import Logo from './Logo';
import BottomSpace from './BottomSpace';
import {cleverreachDe} from '../../tools/cleverreachDe';
import {cleverreachEn} from '../../tools/cleverreachEn';
import SocialMediaLinks from './SocialMediaLinks';
import BottomNav from './BottomNav';
import {connect} from 'react-redux';

const BottomPart = (props) => {
    return <Container>
        <div className={'text-center'}>
            <Link to={`/`}>
                <Logo w={300} h={'auto'}/>
            </Link>
        </div>

        <BottomSpace/>
        <BottomSpace/>

        <div className="text-center">
            {props.language.active === 'de' ?
                <div dangerouslySetInnerHTML={{__html: cleverreachDe}}/>
                : <div dangerouslySetInnerHTML={{__html: cleverreachEn}}/>}

        </div>

        <BottomSpace/>
        <BottomSpace/>

        <SocialMediaLinks/>
        <BottomSpace/>
        <BottomNav language={props.language.active}/>

        <br/>
    </Container>;
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

export default connect(mapStateToProps)(BottomPart);
