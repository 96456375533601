import {FETCH_FAQ} from '../actions';

const defaultState = [];

const faq = (state = defaultState, action) => {

    switch (action.type) {
        case FETCH_FAQ:
            let array = [];
            if (action.payload.data) {
                array = [...action.payload.data.items];
            }
            return array;

        default:
            return state;
    }
};

export default faq;
