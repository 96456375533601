import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

const LanguageToggle = (props) => {

    const [slug, setSlug] = useState('');
    const [langCode, setLangCode] = useState('');

    useEffect(() => {
        try {
            const splitted = props.location.pathname.split('/');
            if (props.language.active === 'en') {
                splitted[1] = 'de';
            } else {
                splitted[1] = 'en';
            }
            setSlug(splitted.join('/') + props.location.search);
        } catch (e) {
        }
    }, [props.location, props.language]);

    useEffect(() => {
        if (props.language.active === 'en') {
            setLangCode('DE');
        } else {
            setLangCode('EN');
        }
    }, [props.language, props.location]);

    return <Link to={slug} className={props.className} style={{width: 40, fontSize: 30, marginTop: -8}}>
        {langCode}
    </Link>;
};

LanguageToggle.defaultProps = {
    className: '',
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

export default withRouter(connect(mapStateToProps)(LanguageToggle));
