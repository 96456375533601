import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import Container from 'react-bootstrap/Container';
import {useTranslation} from 'react-i18next';

import Button from './Button';
import {mixins} from '../../tools/styles';
import CustomMarkdown from './CustomMarkdown';

const SpecialPage = (props) => {
    const {t} = useTranslation(['general']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [caption, setCaption] = useState(props.caption);
    useEffect(() => {
        setCaption(props.caption);
    }, [props.caption]);

    const [text, setText] = useState(props.text);
    useEffect(() => {
        setText(props.text);
    }, [props.text]);

    const [markdown, setMarkdown] = useState(props.markdown);
    useEffect(() => {
        setMarkdown(props.markdown);
    }, [props.markdown]);

    const useStyles = createUseStyles({
        h3: {
            ...mixins.h3,
        },
        unboxed: {
            ...mixins.unboxed,
            margin: '40px 0',
        },
    });

    const classes = useStyles();

    return <Container className={props.className}>
        <h1 className={classes.h3}>{caption}</h1>
        {text && <p className={classes.unboxed}>{text}</p>}
        {markdown && <div className={classes.unboxed}><CustomMarkdown source={markdown}/></div>}
        <br/>
        <Link to={`/${language}/`}><Button variant={'primary'}>{t('toHome')}</Button></Link>
        <br/>
    </Container>;
};

SpecialPage.defaultProps = {
    className: '',
    language: '',
    text: undefined,
    markdown: undefined,
    caption: undefined,
};

export default SpecialPage;
