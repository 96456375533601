import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Item from '../../Blog/Item';

const BlogTeaserList = (props) => {

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <Row>
        {[...props.blog.slice(0, 3)].map((item, index) => {
            return <Col xs={12} sm={6} md={4} key={index} style={{marginBottom: 30}}>
                <Item item={item} language={language}/>
            </Col>;
        })}
    </Row>;
};

BlogTeaserList.defaultProps = {
    language: '',
};

export default BlogTeaserList;
