import React from 'react';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import KontaktFormular from './KontaktFormular';
import {colors, mixins, tools} from '../../tools/styles';

const useStyles = createUseStyles({
    h3: {
        ...mixins.h3,
    },
    h4: {
        ...mixins.h4,
    },
    caption: {
        ...mixins.caption,
    },
    captionSecondaryColor: {
        ...mixins.caption,
        color: colors.secondary,
    },
    unboxed: {
        ...mixins.unboxed,
    },
    p: {
        ...mixins.p,
        marginBottom: 15,
    },
    warning: {
        ...mixins.p,
        color: colors.secondary,
    },
    success: {
        ...mixins.p,
        color: colors.vibrate,
    },
    formular: {
        boxShadow: tools.boxShadow,
        padding: 30,
        zIndex: 900,
        backgroundColor: 'white',
        borderRadius: tools.borderRadius,
    },
});

const Kontakt = (props) => {

    const classes = useStyles();
    const {t} = useTranslation(['contact']);

    const formSubmitSuccess = () => {
        props.history.push(`/${props.language.active}/thank-you-contact/`);
    };

    return <>
        <Helmet>
            <title>{t('pageTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <Container>
            <Row>
                <Col>
                    <KontaktFormular classes={classes} language={props.language.active} successHandler={formSubmitSuccess}/>
                </Col>
            </Row>
        </Container>

    </>;
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

export default connect(mapStateToProps)(Kontakt);
