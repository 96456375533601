import React from 'react';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import CustomMarkdown from '../../components/common/CustomMarkdown';

const FaqItem = (props) => {
    return <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton><span
                className={props.classes.h4}>{props.item.fields.question}</span></AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <CustomMarkdown className={props.classes.p} source={props.item.fields.answer}/>
        </AccordionItemPanel>
    </AccordionItem>;
};

export default FaqItem;
