import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import CustomSpinner from '../../components/common/CustomSpinner';
import {fetchImpressum} from '../../actions';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import SpecialPage from '../../components/common/SpecialPage';
import {getLanguageOfLocation} from '../../tools/tools';

const Impressum = (props) => {
    const {t} = useTranslation(['imprint']);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchImpressum(language);
    }, [props.location]);

    const [text, setText] = useState([]);

    useEffect(() => {
        if (props.meta.impressum.length > 0) {
            setText(props.meta.impressum);
        }
    }, [props.meta]);

    return <>
        <Helmet>
            <title>{t('pageTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        {text.length > 0 ? <SpecialPage
            caption={t('caption')}
            markdown={text[0].fields.text}
            language={props.language.active}
        /> : <CustomSpinner/>}
    </>;
};

const mapStateToProps = (state) => {
    return {
        meta: state.meta,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchImpressum: (language) => {
            dispatch(fetchImpressum(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Impressum);
