import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';

import {buttons} from '../../tools/styles';

const Button = (props) => {
    const useStyles = createUseStyles({
        primaryButton: buttons.primary,
        secondaryButton: buttons.secondary,
    });

    const classes = useStyles();

    const [myClass, setMyClass] = useState(classes.primaryButton);
    useEffect(() => {
        switch (props.variant) {
            case 'secondary':
                setMyClass(classes.secondaryButton);
                break;

            default:
                setMyClass(classes.primaryButton);
        }
    }, [props.variant]);

    return <button className={myClass} type={props.type} onClick={props.onClick}
                   style={props.style}>{props.children}</button>;
};

Button.defaultProps = {
    style: {},
    variant: 'primary', // 'secondary'
    onClick: () => {
    },
    type: 'button',
};

export default Button;
