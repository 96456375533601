import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {FaHome, FaBed, FaBath} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {breakpoints, mixins} from '../../../tools/styles';
import Feature from '../../common/Feature';
import FeatureMeta from '../../common/FeatureMeta';

const useStyles = createUseStyles({
    caption: {
        ...mixins.caption,
    },
    abstand: {
        marginLeft: 30,
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            marginLeft: 12,
        },
    },
});

const VillenFilterMeta = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas']);

    const [villa, setVilla] = useState({});

    useEffect(() => {
        if (props.villa && props.villa.fields) {
            setVilla(props.villa);
        }
    }, [props.villa]);

    return <div className={props.className}>
        {villa.fields && <>
            <Row>
                <Col md={4} xs={6}>
                    <Feature text={`${villa.fields.propertyType}`} icon={<FaHome/>} isActive={true} />
                </Col>
                <Col md={4} xs={6}>
                    <Feature text={`${villa.fields.bedsAmount} ${t('bedrooms')}`} icon={<FaBed/>} isActive={true} />
                </Col>
                <Col md={4} xs={6}>
                    <Feature text={`${villa.fields.bathsAmount} ${t('baths')}`} icon={<FaBath/>} isActive={true} />
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('pool')} isActive={villa.fields.poolsAmount > 0} isDisabled={!(villa.fields.poolsAmount > 0)}/>
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('poolheater')} isActive={villa.fields.hasElectricalPoolHeater} isDisabled={!villa.fields.hasElectricalPoolHeater} />
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('whirlpool')} isActive={villa.fields.hasWhirlpool} isDisabled={!villa.fields.hasWhirlpool}/>
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('grill')} isActive={villa.fields.hasGrill} isDisabled={!villa.fields.hasGrill}/>
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('seaAccess')} isActive={villa.fields.hasGulfAccess} isDisabled={!villa.fields.hasGulfAccess}/>
                </Col>
                <Col md={4} xs={6}>
                    <FeatureMeta text={t('privatePier')} isActive={villa.fields.hasBoatLanding} isDisabled={!villa.fields.hasBoatLanding}/>
                </Col>
            </Row></>}
    </div>;
};

VillenFilterMeta.defaultProps = {
    className: '',
    language: '',
    villa: {},
};

export default VillenFilterMeta;
