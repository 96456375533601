import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {FaHome, FaBath, FaBed, FaWallet} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import CustomBadge from '../../common/CustomBadge';
import {breakpoints, mixins} from '../../../tools/styles';
import CustomCurrency from '../../common/CustomCurrency';

const useStyles = createUseStyles({
    caption: {
        ...mixins.caption,
    },
    abstand: {
        marginLeft: 30,
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            marginLeft: 12,
        },
    },
});

const VillenMeta = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas']);

    const [villa, setVilla] = useState({});

    useEffect(() => {
        if (props.villa && props.villa.fields) {
            setVilla(props.villa);
        }
    }, [props.villa]);

    return <div className={props.className}>
        {villa.fields &&
        <div className={classes.caption}>
            {!props.onlyWeeklyCosts && <>
            {<span className={'d-none d-sm-inline'}>
            <FaHome size={'1.5em'}/> {' '}
                <CustomBadge>{villa.fields.propertyType}</CustomBadge>
            <span className={classes.abstand}/></span>}
            <FaBed size={'1.5em'}/> {' '}
            <CustomBadge>{villa.fields.bedsAmount}</CustomBadge>
            <span className={classes.abstand}/>
            <FaBath size={'1.5em'}/> {' '}
            <CustomBadge>{villa.fields.bathsAmount}</CustomBadge>
            <span className={classes.abstand}/></>}
            <FaWallet size={'1.5em'}/> {' '}
            <CustomBadge>
                <CustomCurrency value={villa.fields.weeklyCosts} prefix={'$'}/> <small>{t('perWeek')}</small>
            </CustomBadge>
        </div>
        }
    </div>;
};

VillenMeta.defaultProps = {
    className: '',
    language: '',
    villa: {},
    onlyWeeklyCosts: false,
};

export default VillenMeta;
