import React from 'react';
import {createUseStyles} from 'react-jss';

import {breakpoints} from '../../tools/styles';

const useStyles = createUseStyles({
    heightSpacer: {
        height: 15,
        [`@media (min-width: ${breakpoints.smMin}px) and (max-width: ${breakpoints.smMax}px)`]: {
            height: 12,
        },
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            height: 9,
        },
    },
});

const HeightSpacer = () => {
    const classes = useStyles();
    return <div className={classes.heightSpacer}/>;
};

export default HeightSpacer;
