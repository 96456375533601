import {FETCH_VILLEN} from '../actions';
import {enhanceAssets} from '../tools';

const defaultState = [];

const villen = (state = defaultState, action) => {

    switch (action.type) {
        case FETCH_VILLEN:
            let array = [];
            if (action.payload.data) {
                array = [...action.payload.data.items];
                array.map(item => {
                    item.fields.images = enhanceAssets(item.fields.images, action.payload.data.includes);
                    return item;
                });
            }

            return array;

        default:
            return state;
    }
};

export default villen;
