import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {cleverreachPlusDe} from '../../../tools/cleverreach-plus-de';
import {cleverreachPlusEn} from '../../../tools/cleverreach-plus-en';

const NewsletterFormular = (props) => {
    const {t} = useTranslation(['newsletterFormular']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <div className={props.classes.formular}>
        <h2 className={props.classes.h4}>{t('caption')}</h2>
        <p className={props.classes.p}>
            {t('subtitle')}
        </p>
        <div>
            {language === 'de' ?
                <div dangerouslySetInnerHTML={{__html: cleverreachPlusDe}}/>
                : <div dangerouslySetInnerHTML={{__html: cleverreachPlusEn}}/>
            }
        </div>
    </div>;
};

NewsletterFormular.defaultProps = {
    classes: {},
    formularName: 'newsletter',
    successHandler: () => {
    },
};

export default NewsletterFormular;
