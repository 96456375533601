import React, {useEffect, useState} from 'react';
import {Route, Switch, Redirect, withRouter, BrowserRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ReactGA from 'react-ga';
import detectBrowserLanguage from 'detect-browser-language';

import Merkliste from '../scenes/Merkliste';
import Villen from '../scenes/Villen';
import VillaDetail from '../scenes/Villen/VillaDetail';
import AuthorDetail from '../scenes/Authors/Detail';
import Impressum from '../scenes/Impressum';
import Datenschutz from '../scenes/Datenschutz';
import FAQ from '../scenes/FAQ';
import BlogDetail from '../scenes/Blog/BlogDetail';
import Blog from '../scenes/Blog';
import Dashboard from '../scenes/Dashboard';
import DankeAnfrage from '../scenes/SpecialPages/DankeAnfrage';
import Error404 from '../scenes/SpecialPages/Error404';
import Kontakt from '../scenes/Kontakt';
import DankeNewsletter from '../scenes/SpecialPages/DankeNewsletter';
import DankeKontakt from '../scenes/SpecialPages/DankeKontakt';
import {changeLanguage} from '../actions';
import BottomSpace from '../components/common/BottomSpace';
import Wave from '../components/common/Wave';
import {colors} from '../tools/styles';
import BottomPart from '../components/common/BottomPart';
import DankeSubscription from '../scenes/SpecialPages/DankeSubscription';

const Routing = (props) => {
    useEffect(() => {
        props.history.listen((location, action) => {
            ReactGA.pageview(location.pathname + location.search);
        });
        try {
            const defaultL = detectBrowserLanguage();
            if (defaultL.split('-')[0] === 'de') {
                setDefaultLanguage('de');
            } else {
                setDefaultLanguage('en');
            }
        } catch (e) {
        }
    }, []);
    const {i18n} = useTranslation();

    const [defaultLanguage, setDefaultLanguage] = useState('');

    useEffect(() => {
        try {
            const language = props.location.pathname.split('/')[1];
            if (props.language.active !== language) {
                props.changeLanguage(language);
            }
            i18n.changeLanguage(language);
        } catch (e) {
        }
    }, [props.location]);

    return <>
        <Switch>
            <Route
                path="/(de|en)"
                render={({match: {url}}) => (
                    <>
                        <Switch>
                            <Route path={`${url}/villas/`} exact component={Villen}/>
                            <Route path={`${url}/villas/:slug`} component={VillaDetail}/>
                            <Route path={`${url}/authors/:slug`} component={AuthorDetail}/>
                            <Route path={`${url}/wishlist/`} exact component={Merkliste}/>
                            <Route path={`${url}/imprint`} component={Impressum}/>
                            <Route path={`${url}/data-privacy`} component={Datenschutz}/>
                            <Route path={`${url}/faq`} component={FAQ}/>
                            <Route path={`${url}/blog/:cat/:slug`} component={BlogDetail}/>
                            <Route path={`${url}/blog/:cat`} component={Blog}/>
                            <Route path={`${url}/blog`} component={Blog}/>
                            <Route path={`${url}/thank-you-booking`} component={DankeAnfrage}/>
                            <Route path={`${url}/thank-you-newsletter`} component={DankeNewsletter}/>
                            <Route path={`${url}/thank-you-subscription`} component={DankeSubscription}/>
                            <Route path={`${url}/thank-you-contact`} component={DankeKontakt}/>
                            <Route path={`${url}/contact`} component={Kontakt}/>
                            <Route path={`${url}/`} exact component={Dashboard}/>
                            <Route component={Error404}/>
                        </Switch>
                    </>
                )}
            />
            {defaultLanguage &&
            <Redirect from="*" to={`/${defaultLanguage}`}/>}
        </Switch>
        <BottomSpace/>
        <BottomSpace/>
        <BottomSpace/>
        <BottomSpace/>
        <div style={{overflowX: 'hidden',}}>
            <Wave/>
        </div>
        <div style={{backgroundColor: colors.secondary,}}>
            <BottomPart/>
        </div>
    </>;
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => {
            dispatch(changeLanguage(language));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));
