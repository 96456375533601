import {SET_BLOG_FILTER} from '../actions';

const defaultState = {
    filter: {
        text: '',
    },
};

const blogFilter = (state = defaultState, action) => {
    const newState = {...state};

    switch (action.type) {
        case SET_BLOG_FILTER:
            newState.filter = action.payload;
            return newState;

        default:
            return state;
    }
};

export default blogFilter;
