import React, {useEffect, useState} from 'react';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import {createUseStyles} from 'react-jss';

import {tools} from '../../tools/styles';

const useStyles = createUseStyles({
    frame: {
        borderRadius: tools.borderRadius,
    },
});

const YoutubeVideo = (props) => {
    const classes = useStyles();
    const [youtubeId, setYoutubeId] = useState(undefined);

    useEffect(() => {
        if (props.id) {
            setYoutubeId(props.id);
        }
    }, [props.id]);

    return <>{
        youtubeId &&
        <ResponsiveEmbed aspectRatio="16by9" className={classes.frame}>
            <iframe width="1400" height="960" src={`https://www.youtube.com/embed/${youtubeId}`} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </ResponsiveEmbed>
    }</>;
};

export default YoutubeVideo;
