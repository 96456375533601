import {FETCH_DATENSCHUTZ, FETCH_IMPRESSUM, FETCH_DASHBOARD} from '../actions';
import {enhanceAsset} from '../tools';

const defaultState = {
    datenschutz: [],
    impressum: [],
    dashboard: [],
};

const meta = (state = defaultState, action) => {

    const newState = {...state};

    switch (action.type) {
        case FETCH_DATENSCHUTZ:
            if (action.payload.data) {
                newState.datenschutz = action.payload.data.items;
            }
            return newState;

        case FETCH_IMPRESSUM:
            if (action.payload.data) {
                newState.impressum = action.payload.data.items;
            }
            return newState;

        case FETCH_DASHBOARD:
            if (action.payload.data) {
                [...action.payload.data.items].map(item => {
                    item.fields.headerImage = enhanceAsset(item.fields.headerImage, action.payload.data.includes);
                    return item;
                });
                newState.dashboard = action.payload.data.items;
            }
            return newState;

        default:
            return state;
    }
};

export default meta;
