import React from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import SpecialPage from '../../components/common/SpecialPage';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';

const Error404 = (props) => {
    const {t} = useTranslation(['errorPage']);
    return <>
        <Helmet>
            <title>{t('pageTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <SpecialPage
            className={'text-center'}
            caption={t('caption')}
            text={t('text')}
            language={props.language.active}
        />
    </>;
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

export default connect(mapStateToProps)(Error404);
