import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {BlogCategories} from '../../enums/blogCategories';
import CategoryButton from './CategoryButton';

const CategoryHeader = (props) => {
    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [selectedCategory, setSelectedCategory] = useState(props.selectedCategory);

    useEffect(() => {
        switch (props.selectedCategory) {
            case BlogCategories.TRIPGOALS:
            case BlogCategories.FLORIDA:
            case BlogCategories.TRAVELTIPS:
                setSelectedCategory(props.selectedCategory);
                break;

            default:
                setSelectedCategory('');
        }
    }, [props.selectedCategory]);

    return <Row>
        <Col sm={12} lg={6} xl={4}>
            <CategoryButton category={BlogCategories.TRIPGOALS} selectedCategory={selectedCategory}
                            blogCategoryClickHandler={props.blogCategoryClickHandler} language={language}/>
        </Col>
        <Col sm={12} md={6} xl={4}>
            <CategoryButton category={BlogCategories.FLORIDA} selectedCategory={selectedCategory}
                            blogCategoryClickHandler={props.blogCategoryClickHandler} language={language}/>
        </Col>
        <Col sm={12} md={6} xl={4}>
            <CategoryButton category={BlogCategories.TRAVELTIPS} selectedCategory={selectedCategory}
                            blogCategoryClickHandler={props.blogCategoryClickHandler} language={language}/>
        </Col>
    </Row>;
};

CategoryHeader.defaultProps = {
    blogCategoryClickHandler: () => {
    },
    selectedCategory: '',
    language: '',
};

export default CategoryHeader;
