import {MERKE_VILLA, ENTMERKE_VILLA} from '../actions';

const defaultState = {};

const merkliste = (state = defaultState, action) => {
    const newState = {...state};

    switch (action.type) {
        case MERKE_VILLA:
            newState[action.payload] = true;
            return newState;

        case ENTMERKE_VILLA:
            delete newState[action.payload];
            return newState;

        default:
            return state;
    }
};

export default merkliste;
