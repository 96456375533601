import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';

import 'rc-slider/assets/index.css';
import './rangeslider.css';

import {setFilter as setFilterRedux, setPage as setPageRedux} from '../../actions';
import FeatureMeta from '../../components/common/FeatureMeta';
import {createUseStyles} from 'react-jss';
import {mixins} from '../../tools/styles';

const useStyles = createUseStyles({
    caption: {
        ...mixins.caption,
    },
});

const FilterRow = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas']);
    const [filter, setFilter] = useState(props.filter);

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);

    const clickSortHandler = () => {
        props.setFilterRedux({
            ...filter,
            sortAsc: !filter.sortAsc,
        });
        props.setPageRedux(1);
    };

    const clickToggle = (property) => {
        props.setFilterRedux({
            ...filter,
            [property]: !filter[property],
        });
        props.setPageRedux(1);
    };

    return <>
        <Row>
            <Col>
                <div className={classes.caption}>
                    {t('moreFilters')}
                </div>
            </Col>
        </Row>
        <Row>
            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('pool')}
                             isButton={true}
                             isActive={filter['hasPool']}
                             onClick={() => clickToggle('hasPool')}/>
            </Col>
            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('poolheater')}
                             isButton={true}
                             isActive={filter['hasPoolHeater']}
                             onClick={() => clickToggle('hasPoolHeater')}/>
            </Col>
            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('whirlpool')}
                             isButton={true}
                             isActive={filter['hasWhirlpool']}
                             onClick={() => clickToggle('hasWhirlpool')}/>
            </Col>

            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('grill')}
                             isButton={true}
                             isActive={filter['hasGrill']}
                             onClick={() => clickToggle('hasGrill')}/>
            </Col>

            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('seaAccess')}
                             isButton={true}
                             isActive={filter['hasGulfAccess']}
                             onClick={() => clickToggle('hasGulfAccess')}/>
            </Col>

            <Col xl={2} lg={4} sm={6}>
                <FeatureMeta text={t('privatePier')}
                             isButton={true}
                             isActive={filter['hasBoatLanding']}
                             onClick={() => clickToggle('hasBoatLanding')}/>
            </Col>
        </Row>
    </>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterRedux: (filter) => {
            dispatch(setFilterRedux(filter));
        },
        setPageRedux: (page) => {
            dispatch(setPageRedux(page));
        },
    };
};

export default connect(null, mapDispatchToProps)(FilterRow);
