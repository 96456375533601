import React from 'react';
import {hydrate, render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import {PersistGate} from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import './i18next';
import {store, persistor} from './store';
import * as serviceWorker from './serviceWorker';
import Routing from './routing';


const tagManagerArgs = {
    gtmId: 'GTM-M7XN7RV',
    events: {
      sendUserInfo: 'userInfo'
    }
};

if (navigator.userAgent !== 'ReactSnap') {
  TagManager.initialize(tagManagerArgs);
}

// ReactGA.initialize('UA-150068689-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const part = <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <Router>
            <ScrollToTop>
                <Routing/>
            </ScrollToTop>
        </Router>
    </PersistGate>
</Provider>;

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(part, rootElement);
} else {
    render(part, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
