import {FETCH_AUTHORS} from '../actions';

const defaultState = [];

const authors = (state = defaultState, action) => {

    switch (action.type) {
        case FETCH_AUTHORS:
            if (action.payload.items) {
                return action.payload.items
            }
            return state;

        default:
            return state;
    }
};

export default authors;
