import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {FaHeart, FaHeartBroken} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import {colors, mixins, tools} from '../../tools/styles';

const MerkToggle = (props) => {

    const {t} = useTranslation(['villas']);

    const useStyles = createUseStyles({
        link: {
            ...mixins.caption,
            color: colors.secondary,
            cursor: 'pointer',
            marginBottom: 30,
            transition: tools.transition,
            '&:hover': {
                color: colors.secondary,
                opacity: 0.8,
                textDecoration: 'none',
            },
        },
    });

    const classes = useStyles();

    const [isGemerkt, setIsGemerkt] = useState(props.isGemerkt);

    useEffect(() => {
        setIsGemerkt(props.isGemerkt);
    }, [props.isGemerkt]);

    return <span className={classes.link} onClick={props.onClick}>{isGemerkt ? <><FaHeartBroken/> {t('removeFrom')}</> : <><FaHeart/> {t('addTo')}</>}</span>;
};

MerkToggle.defaultProps = {
    isGemerkt: false,
    onClick: () => {
    },
};

export default MerkToggle;
