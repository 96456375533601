import React from 'react';
import Badge from 'react-bootstrap/Badge';

import {colors} from '../../tools/styles';


const CustomBadge = (props) => {
    return <Badge variant={'success'} style={{backgroundColor: colors[props.variant]}}>{props.children}</Badge>;
};

CustomBadge.defaultProps = {
    variant: 'secondary',
};

export default CustomBadge;

