import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import {colors, fonts, tools, mixins} from '../../tools/styles';
import Placeholder from '../../components/common/Placeholder';
import BlogCategoryIcon from '../../components/common/BlogCategoryIcon';
import BlogCategoryCaption from '../../components/common/BlogCategoryCaption';
import CustomMarkdown from '../../components/common/CustomMarkdown';

const useStyles = createUseStyles({
    caption: {
        ...mixins.caption,
    },
    blogtext: {
        ...mixins.p,
    },
    blogItem: {
        transition: tools.transition,
        backgroundColor: 'white',
        boxShadow: tools.boxShadow,
        borderRadius: tools.borderRadius,
        '&:hover': {
            boxShadow: tools.boxShadowHover,
        },
        '&:hover $specialButton': {
            width: 'calc(100% - 63px)',
            borderRadius: tools.borderRadius,
            '& $specialButtonText': {
                fontSize: 18,
                opacity: 1,
            },
        },
    },
    blogPost: {
        padding: 20,
    },
    blogLink: {
        ...mixins.caption,
        transition: tools.transition,
        color: colors.secondary,

        '&:hover': {
            color: colors.secondary,
            opacity: 0.8,
            textDecoration: 'none',
        },
    },
    specialButton: {
        width: 44,
        transition: tools.transition,
        backgroundColor: 'white',
        position: 'absolute',
        top: 20,
        left: 30,
        height: 44,
        borderRadius: 30,
        cursor: 'pointer',
    },
    specialButtonIcon: {
        transition: tools.transition,
        color: colors.secondary,
        position: 'absolute',
        top: 12,
        left: 12,
        lineHeight: '30px',
    },
    specialButtonText: {
        color: colors.primary,
        fontSize: 0,
        transition: tools.transition,
        opacity: 0,
        fontFamily: fonts.secondary,
        position: 'absolute',
        top: 6,
        left: 50,
        lineHeight: '34px',
    },
    figureImage: {
        marginBottom: 10,
        width: '100%',
    },
});

const BlogItem = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['blog']);

    const [item, setItem] = useState(props.item);
    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const blogCategoryClickHandler = (event, category) => {
        event.preventDefault();
        props.history.push(`/${props.language}/blog/${category}`);
    };

    return <div className={classes.blogItem} style={{height: props.isFullHeight ? '100%' : 'none', paddingBottom:  props.isFullHeight ? 30 : 0,}}>
        <div>
            <Link to={`/${language}/blog/${props.category}/${item.fields.slug}?id=${item.fields.id}`}>
                <Placeholder className={classes.figureImage}
                             image={`${item.fields.teaserImage.fields.file.url}?w=400&h=400&fit=thumb`}
                             placeholder={'/images/300x300.png'}/>
            </Link>

            <div className={classes.specialButton}
                 onClick={(event) => blogCategoryClickHandler(event, item.fields.category)}>
                <BlogCategoryIcon className={classes.specialButtonIcon} category={item.fields.category}/>
                <BlogCategoryCaption className={classes.specialButtonText} category={item.fields.category}/>
            </div>
        </div>
        <div className={classes.blogPost}>
            <div className={classes.caption}>
                {item.fields.titel}
            </div>
            <CustomMarkdown className={classes.blogtext} source={item.fields.teaser}/>

            <Link
                to={`/${language}/blog/${props.category}/${item.fields.slug}?id=${item.fields.id}`}
                style={{position: props.isFullHeight ? 'absolute' : 'relative', bottom: props.isFullHeight ? 10 : 0,
                    left: props.isFullHeight ? 40 : 0,}}
                className={classes.blogLink}>
                {t('readArticleCta')}
            </Link>
        </div>
    </div>;
};

BlogItem.defaultProps = {
    category: 'all',
    item: {},
    language: '',
    isFullHeight: true,
};

export default withRouter(BlogItem);
