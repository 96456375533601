import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';

import Button from '../../components/common/Button';
import {colors, fonts} from '../../tools/styles';
import BootstrapPagination from 'react-bootstrap/Pagination';

const useStyles = createUseStyles({
    inactive: {
        cursor: 'pointer',
        width: 40,
        height: 40,
        textAlign: 'center',
        border: `1px solid ${colors.secondary}`,
        borderRadius: 50,
        fontFamily: fonts.secondary,
        fontSize: 26,
        backgroundColor: 'white',
        color: colors.secondary,
        marginRight: 7,
    },
    active: {
        cursor: 'pointer',
        width: 40,
        height: 40,
        textAlign: 'center',
        border: `1px solid ${colors.secondary}`,
        borderRadius: 50,
        fontFamily: fonts.secondary,
        fontSize: 26,
        backgroundColor: colors.secondary,
        color: 'white',
        marginRight: 10,
    },
});

const CustomPagination = (props) => {
    const [page, setPage] = useState(props.page);
    const classes = useStyles();
    useEffect(() => {
        setPage(props.page);
    }, [props.page]);

    const changePageHandler = (event, page) => {
        event.preventDefault();
        props.onClick(page);
    };

    const Items = () => {
        let active = page;
        const items = [];

        for (let number = 1; number <= props.max; number++) {
            items.push(
                <div className={number === active ? classes.active : classes.inactive} key={number}
                     onClick={(e) => changePageHandler(e, number)}>{number}
                </div>
            );
        }
        return items;
    };

    return props.max > 1 ? <BootstrapPagination>
        <Items/>
    </BootstrapPagination> : null;
};

CustomPagination.defaultProps = {
    page: 1,
    max: 1,
    onClick: (page) => {
    }
};

export default CustomPagination;
