import React from 'react';
import {createUseStyles} from 'react-jss';
import {FaFacebook, FaInstagram, FaPinterest} from 'react-icons/fa';

import {tools} from '../../tools/styles';

const SocialMediaLinks = (props) => {
    const useStyles = createUseStyles({
        icon: {
            color: 'white',
            margin: 10,
            transition: tools.transition,
            cursor: 'pointer',
            '&:hover': {
                opacity: 0.8,
            },
        },
    });

    const classes = useStyles();

    return <div className={props.className}>
        <a href="https://www.facebook.com/villasofparadise/" target={'_blank'}><FaFacebook size={props.size} className={classes.icon}/></a>
        <a href="https://www.instagram.com/villasofparadise/" target={'_blank'}><FaInstagram size={props.size} className={classes.icon}/></a>
        <a href="https://www.pinterest.de/villasofparadise/" target={'_blank'}><FaPinterest size={props.size} className={classes.icon}/></a>
    </div>;
};

SocialMediaLinks.defaultProps = {
    size: 40,
    className: 'text-center',
};

export default SocialMediaLinks
