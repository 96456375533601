import React, {useEffect, useState} from 'react';

import Wave from './Wave';

const HeaderImage = (props) => {

    const [image, setImage] = useState('');
    useEffect(() => {
        setImage(props.image);
    }, [props.image]);

    const [height, setHeight] = useState(props.h);
    useEffect(() => {
        setHeight(props.h);
    }, [props.h]);

    return <div className={props.className}>
        <div style={{
            width: '100%',
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: height || 'auto',
        }}/>
        <div style={{marginTop: '-198px'}}>
            <Wave color={'white'}/>
        </div>
    </div>;

};
HeaderImage.defaultProps = {
    h: undefined,
    image: '',
};

export default HeaderImage;
