import React from 'react';
import {FaFacebookF, FaTwitter, FaWhatsapp, FaPaperPlane} from 'react-icons/fa';
import {createUseStyles} from 'react-jss';

import {colors, tools, breakpoints} from '../../tools/styles';

const useStyles = createUseStyles({
    box: {
        transition: tools.transition,
        borderRadius: 50,
        backgroundColor: 'white',
        height: 40,
        width: 40,
        border: `1px solid ${colors.secondary}`,
        textAlign: 'center',
        float: 'right',
        marginRight: 10,
        color: colors.secondary,
        '&:hover': {
            backgroundColor: colors.secondary,
            color: 'white',
        },
        [`@media (max-width: ${breakpoints.smMax}px)`]: {
            float: 'left',
        },
    },
    shareLink: {
        fontSize: '20px',
        lineHeight: '34px',
    },
});

const SocialShare = (props) => {

    const classes = useStyles();

    return <div className={props.className}>
        <a className={classes.shareLink} href={`https://facebook.com/sharer/sharer.php?u=${window.location.href}`}
           target={'_blank'}>
            <div className={classes.box}>
                <FaFacebookF/>
            </div>
        </a>
        <a className={classes.shareLink}
           href={`https://twitter.com/intent/tweet/?text=&url=${window.location.href}`} target={'_blank'}>
            <div className={classes.box}>
                <FaTwitter/>
            </div>
        </a>
        <a className={classes.shareLink} href={`whatsapp://send?text=${window.location.href}`}
           target={'_blank'}>
            <div className={classes.box}>
                <FaWhatsapp/>
            </div>
        </a>
        <a className={classes.shareLink} href={`mailto:?subject=&body=${window.location.href}`}
           target={'_blank'}>
            <div className={classes.box}>
                <FaPaperPlane/>
            </div>
        </a>
    </div>;
};

SocialShare.defaultProps = {
    className: '',
};

export default SocialShare;
