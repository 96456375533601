import React from 'react';
import {createUseStyles} from 'react-jss';

import {colors, mixins, tools} from '../../tools/styles';

const Feature = (props) => {

    const useStyles = createUseStyles({
        div: {
            ...mixins.caption,
            borderRadius: tools.borderRadius,
            boxShadow: tools.boxShadow,
            padding: 10,
            border: '2px solid',
            marginTop: 6,
            transition: tools.transition,
            '&:hover' : {
                boxShadow: props.isButton ? 'none' : tools.boxShadow,
            }
        },
    });

    const classes = useStyles();

    return <div className={classes.div}
                onClick={props.onClick}
                style={{
                    borderColor: props.isActive ? colors[props.variant] : 'lightgrey',
                    color: props.isActive ? 'white' : 'lightgrey',
                    backgroundColor: props.isActive ? colors[props.variant] : 'white',
                    cursor: props.isButton && !props.isDisabled ? 'pointer' : 'auto',
                }}>
        {props.icon}
        <span style={{marginLeft: 20, color: props.isDisabled ? 'lightgrey' : (props.isActive ? 'white' : colors.primary)}}>{props.text}</span>
    </div>;
};

Feature.defaultProps = {
    isButton: false,
    isDisabled: false,
    isActive: false,
    text: '',
    icon: <></>,
    variant: 'vibrate',
    onClick: () => {
    },
};

export default Feature;

