import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import BlogCategoryIcon from '../../components/common/BlogCategoryIcon';
import {BlogCategories} from '../../enums/blogCategories';
import {colors, fonts, mixins, tools} from '../../tools/styles';
import BlogCategoryCaption from '../../components/common/BlogCategoryCaption';

const useStyles = createUseStyles({
    h3: {
        ...mixins.h3,
    },
    box: {
        backgroundColor: colors.secondary,
        textAlign: 'left',
        color: 'white',
        padding: 20,
        marginTop: 10,
        marginBottom: 10,
        cursor: 'pointer',
        borderRadius: tools.borderRadius,
        boxShadow: tools.boxShadow,
        border: `2px solid ${colors.secondary}`,
        '&:hover': {
            opacity: 0.8,
        }
    },
    inactiveBox: {
        backgroundColor: 'white',
        textAlign: 'left',
        color: colors.secondary,
        padding: 20,
        marginTop: 10,
        marginBottom: 10,
        cursor: 'pointer',
        borderRadius: tools.borderRadius,
        boxShadow: tools.boxShadow,
        border: `2px solid ${colors.secondary}`,
        '&:hover': {
            backgroundColor: colors.secondary,
            color: 'white',
        }
    },
    caption: {
        fontFamily: fonts.secondary,
        fontSize: 22,
    },
    sub: {
        fontFamily: fonts.primary,
        fontSize: 14,
    },
});

const CategoryButton = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['blog']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [category, setCategory] = useState(props.category);
    const [subTitle, setSubTitle] = useState('');
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (props.category) {
            switch (props.category) {
                case BlogCategories.TRIPGOALS:
                    setSubTitle(t('tripgoalsDesc'));
                    break;
                case BlogCategories.FLORIDA:
                    setSubTitle(t('floridaDesc'));
                    break;
                case BlogCategories.TRAVELTIPS:
                    setSubTitle(t('traveltipsDesc'));
                    break;
            }
            setCategory(props.category);
        }
    }, [props.category, language, props.selectedCategory]);

    useEffect(() => {
        if (props.selectedCategory !== '' && props.category !== '') {
            if (props.selectedCategory === props.category) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        } else {
            setIsActive(true);
        }
    }, [props.selectedCategory, props.category]);

    return <div className={isActive ? classes.box : classes.inactiveBox} onClick={() => props.blogCategoryClickHandler(category)}>
        <ul className="list-inline" style={{marginBottom: 0}}>
            <li className="list-inline-item">
                <BlogCategoryIcon category={category} size={40} showTitle={false} display={'block'}/>
            </li>
            <li className="list-inline-item" style={{marginLeft: 10}}>
                <div className={classes.caption}>
                    <BlogCategoryCaption category={category}/>
                </div>
                <div className={classes.sub}>
                    {subTitle}
                </div>
            </li>
        </ul>
    </div>;
};

CategoryButton.defaultProps = {
    blogCategoryClickHandler: () => {
    },
    selectedCategory: '',
    category: '',
    language: '',
};

export default CategoryButton;
