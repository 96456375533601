import React from 'react';
import {createUseStyles} from 'react-jss';
import Markdown from 'react-markdown';

import {mixins, tools} from '../../tools/styles';

const useStyles = createUseStyles({
    markdown: {
        '& img': {
            width: '100%',
            height: 'auto',
            borderRadius: tools.borderRadius,
            boxShadow: tools.boxShadow,
        },
        '& h2': {
            ...mixins.h4,
        },
        '& h3': {
            ...mixins.h5,
        },
    },
});

const CustomMarkdown = (props) => {
    const classes = useStyles();

    return <div className={props.className}>
        <Markdown {...props} className={classes.markdown}/>
    </div>;
};

CustomMarkdown.defaultProps = {
    className: '',
};

export default CustomMarkdown;
