import React from 'react';
import {connect} from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import {setBlogFilter as setFilterRedux} from '../../actions';
import {mixins} from '../../tools/styles';
import InputText from '../../components/common/InputText';

const useStyles = createUseStyles({
    p: {
        ...mixins.p,
    },
});

const BlogFilter = (props) => {
    const {t} = useTranslation(['blog']);
    const classes = useStyles();

    const changeTextHandler = (event) => {
        props.setFilterRedux({
            ...props.filter.filter,
            text: event.currentTarget.value,
        });
    };

    return <Form onSubmit={(e) => e.preventDefault()}>
        <Row>
            <Col xs={12} sm={{span: 8, offset: 2}} md={{span: 4, offset: 4}}>
                <Card.Body>
                    <Form.Group>
                        <InputText className={classes.p} type="text" value={props.filter.filter.text}
                                   onChange={changeTextHandler}
                                   placeholder={t('filterText')}/>
                    </Form.Group>
                </Card.Body>
            </Col>
        </Row>
    </Form>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterRedux: (filter) => {
            dispatch(setFilterRedux(filter));
        },
    };
};

export default connect(null, mapDispatchToProps)(BlogFilter);
