import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import {Container, Navbar, Nav, Row} from 'react-bootstrap';
import {createUseStyles} from 'react-jss';
import {isMobileOnly} from 'react-device-detect';
import {FaHeart} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import Logo from './Logo';
import {breakpoints, colors, mixins} from '../../tools/styles';

import './TopNavBar.scss';
import LanguageToggle from './LanguageToggle';

const TopNavBar = (props) => {
    const useStyles = createUseStyles({
        navBar: {
            ...mixins.caption,
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 990,
            lineHeight: 1.4,
            letterSpacing: 0,
            background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(100,100,100,0) 100%)',
            padding: 20,
            [`@media (max-width: ${breakpoints.mdMax}px)`]: {
                padding: 10,
            },
        },
        navBarLink: {
            '&:visited, &:link': {
                color: 'rgba(255,255,255,0.8)!important',
            },
            '&:hover': {
                color: 'rgba(255,255,255,0.5)!important',
            }
        },
        black: {
            background: colors.vibrate,
        },
    });

    const classes = useStyles();

    const { t } = useTranslation(['general']);

    const [className, setClassName] = useState(`${classes.navBar}`);

    useEffect(() => {
        if (props.transparent) {
            setClassName(`${classes.navBar}`);
        } else {
            setClassName(`${classes.navBar} ${classes.black}`);
        }
    }, [props.transparent]);

    const [merkliste, setMerkliste] = useState({});
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);

    const toggleMobileMenu = () => {
        if (!className.includes('mobile')) {
            setClassName(`${className} mobile`);
        } else {
            setClassName(className.replace(' mobile', ''));
        }
    };

    return <Navbar expand="lg" variant="dark" className={className}>
        <Container>
            <LinkContainer to={`/${props.language.active}/`}>
                <Navbar.Brand>
                    <Logo w={isMobileOnly ? 200 : 300} h={'auto'}/>
                </Navbar.Brand>
            </LinkContainer>

            <div className="mobile-menu">
                <input id="toggle" name="toggle" type="checkbox"/>
                <label className="toggle-container" htmlFor="toggle" onClick={(e) => toggleMobileMenu(e)}>
                    <span className="button button-toggle"/>
                </label>
                <nav className="nav">
                    <Link to={`/${props.language.active}/villas`} className="nav-item">{t('villas')}</Link>
                    {merkliste && Object.keys(merkliste).length > 0 &&
                    <Link to={`/${props.language.active}/wishlist`} className="nav-item"><FaHeart/> {t('wishlist')}</Link>}
                    <Link to={`/${props.language.active}/blog`} className="nav-item">{t('blog')}</Link>
                    <Link to={`/${props.language.active}/contact`} className="nav-item">{t('contact')}</Link>
                    <Link to={`/${props.language.active}/faq`} className="nav-item">{t('faq')}</Link>
                    <LanguageToggle className="nav-item"/>
                </nav>
            </div>

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <LinkContainer to={`/${props.language.active}/villas`}>
                        <Nav.Link className={classes.navBarLink}>{t('villas')}</Nav.Link>
                    </LinkContainer>
                    {merkliste && Object.keys(merkliste).length > 0 &&
                    <LinkContainer to={`/${props.language.active}/wishlist`}>
                        <Nav.Link className={classes.navBarLink}><FaHeart/> {t('wishlist')}</Nav.Link>
                    </LinkContainer>}
                    <LinkContainer to={`/${props.language.active}/blog`}>
                        <Nav.Link className={classes.navBarLink}>{t('blog')}</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`/${props.language.active}/contact`}>
                        <Nav.Link className={classes.navBarLink}>{t('contact')}</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`/${props.language.active}/faq`}>
                        <Nav.Link className={classes.navBarLink}>{t('faq')}</Nav.Link>
                    </LinkContainer>
                    <LanguageToggle className={`${classes.navBarLink} nav-link`}/>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
};

const mapStateToProps = (state) => {
    return {
        merkliste: state.merkliste,
        language: state.language,
    };
};

export default connect(mapStateToProps)(TopNavBar);
