import React from 'react';
import {withRouter} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import {colors, mixins, tools} from '../../tools/styles';

const useStyles = createUseStyles({
    link: {
        ...mixins.caption,
        color: colors.secondary,
        cursor: 'pointer',
        marginBottom: 30,
        transition: tools.transition,
        '&:hover': {
            color: colors.secondary,
            opacity: 0.8,
            textDecoration: 'none',
        }
    }
});

const BackLink = (props) => {
    const {t} = useTranslation(['general']);
    const classes = useStyles();
    return <p className={classes.link} onClick={props.history.goBack}>{props.text || t('goBack')}</p>;
};

BackLink.defaultProps = {
    text: undefined,
};

export default withRouter(BackLink);
