import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NetlifyForm from 'react-netlify-form';
import {useTranslation} from 'react-i18next';

import 'moment/locale/de.js';

import 'rc-datepicker/lib/style.css';

import Button from '../../components/common/Button';
import CustomSpinner from '../../components/common/CustomSpinner';

const KontaktFormular = (props) => {

    const {t} = useTranslation(['contact']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [anrede, setAnrede] = useState('');
    const [freitext, setFreitext] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isPaket, setIsPaket] = useState(false);
    const [strasse, setStrasse] = useState('');
    const [ort, setOrt] = useState('');
    const [land, setLand] = useState(t('germany'));

    return <div className={props.classes.formular}>
        <h1 className={props.classes.h3}>{t('caption')}</h1>
        <br/>
        <NetlifyForm name={props.formularName} onSuccess={props.successHandler}>
            {({loading, error, success}) => (
                <div>
                    {loading &&
                    <div className={props.classes.p}><CustomSpinner/></div>
                    }
                    {error &&
                    <div className={props.classes.warning}>{t('errorMsg')} <br/><br/></div>
                    }
                    {success &&
                    <div className={props.classes.success}>{t('successMsg')}</div>
                    }
                    {!loading && !success &&
                    <div>
                        <Row>
                            <Col md={6}>
                                <Form.Label className={props.classes.caption}>{t('salutation')}</Form.Label>

                                <Form.Control as="select" className={props.classes.p} name="Anrede"
                                              value={anrede} onChange={(e) => setAnrede(e.target.value)}>
                                    <option></option>
                                    <option>{t('mrs')}</option>
                                    <option>{t('mr')}</option>
                                </Form.Control>

                            </Col>
                            <Col md={6}>
                                <Form.Label className={props.classes.caption}>{t('name')}</Form.Label>
                                <Form.Control className={props.classes.p} type="text"
                                              name="Name"
                                              value={name}
                                              onChange={(e) => setName(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Label className={props.classes.caption}>{t('mail')}</Form.Label>
                                <Form.Control className={props.classes.p} type="email"
                                              name="Mail"
                                              value={email}
                                              onChange={(e) => setEmail(e.target.value)}
                                              required/>
                            </Col>
                            <Col md={6}>
                                <Form.Label className={props.classes.caption}>{t('phone')}</Form.Label>
                                <Form.Control className={props.classes.p} type="text"
                                              name="Telefonnummer"
                                              value={phone}
                                              onChange={(e) => setPhone(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Label className={props.classes.caption}>{t('msg')}</Form.Label>
                                <Form.Control as={'textarea'} rows={3} className={props.classes.p} type="text"
                                              name="Freitext"
                                              value={freitext}
                                              onChange={(e) => setFreitext(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col xs={12}>*/}
                        {/*        <FormCheck>*/}
                        {/*            <FormCheck.Input type="checkbox" name="paket" value={isPaket}*/}
                        {/*                             onChange={(e) => setIsPaket(e.target.checked)}*/}
                        {/*                             className={'squaredThree'}/>*/}
                        {/*            <FormCheck.Label className={props.classes.p}>{t('packageText')}</FormCheck.Label>*/}
                        {/*        </FormCheck>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        {isPaket && <>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className={props.classes.caption}>{t('street')}</Form.Label>
                                    <Form.Control className={props.classes.p} type="text"
                                                  name="Strasse"
                                                  value={strasse}
                                                  onChange={(e) => setStrasse(e.target.value)}
                                                  required/>
                                </Col>
                                <Col md={6}>
                                    <Form.Label className={props.classes.caption}>{t('zipAndCity')}</Form.Label>
                                    <Form.Control className={props.classes.p} type="text"
                                                  name="Ort"
                                                  value={ort}
                                                  onChange={(e) => setOrt(e.target.value)}
                                                  required/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className={props.classes.caption}>{t('country')}</Form.Label>
                                    <Form.Control className={props.classes.p} type="text"
                                                  name="Land"
                                                  value={land}
                                                  onChange={(e) => setLand(e.target.value)}
                                                  required/>
                                </Col>
                            </Row>
                        </>}
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlId="dsgvo">
                                    <FormCheck>
                                        <FormCheck.Input type="checkbox" required/>
                                        <FormCheck.Label className={props.classes.p} name={'dsgvo'}
                                                         style={{fontSize: '13px', lineHeight: '16px'}}>{t('dsgvoText')}
                                            {' '}<Link to={`/${language}/data-privacy`}>{t('dsgvoLinktext')}</Link>.
                                            *</FormCheck.Label>
                                    </FormCheck>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div data-netlify-recaptcha="true"/>
                                <Button type={'submit'}>{t('submit')}</Button>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
            )}
        </NetlifyForm>
    </div>;
};

KontaktFormular.defaultProps = {
    classes: {},
    formularName: 'kontakt',
    successHandler: () => {
    },
    language: '',
};

export default KontaktFormular;
