import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NetlifyForm from 'react-netlify-form';
import {DatePickerInput} from 'rc-datepicker';
import {useTranslation} from 'react-i18next';

import 'moment/locale/de.js';

import 'rc-datepicker/lib/style.css';

import Button from '../../../components/common/Button';
import CustomSpinner from '../../../components/common/CustomSpinner';

const AnfrageFormular = (props) => {

    const {t} = useTranslation(['formular']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [villa, setVilla] = useState('');
    const [anreise, setAnreise] = useState(new Date());
    const [abreise, setAbreise] = useState(new Date());
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const anreiseChangeHandler = (jsDate, dateString) => {
        setAnreise(jsDate);
    };

    const abreiseChangeHandler = (jsDate, dateString) => {
        setAbreise(jsDate);
    };

    useEffect(() => {
        if (props.villa !== '') {
            setVilla(props.villa);
        }
    }, [props.villa]);

    return <div className={props.classes.formular} style={{
        position: props.fixed ? 'sticky' : 'relative',
        marginTop: props.fixed ? 0 : 30,
        top: props.fixed ? 110 : 0,
    }}>
        <h2 className={props.classes.h4}>{t("caption")}</h2>
        <p className={props.classes.p}>
            {t("subtitle")}
        </p>
        {villa &&
        <NetlifyForm name={props.formularName} onSuccess={props.successHandler}>
            {({loading, error, success}) => (
                <div>
                    {loading &&
                    <div className={props.classes.p}><CustomSpinner/></div>
                    }
                    {error &&
                    <div className={props.classes.warning}>{t("errorMsg")} <br/><br/></div>
                    }
                    {success &&
                    <div className={props.classes.success}>{t("successMsg")}</div>
                    }
                    {!loading && !success &&
                    <div>
                        <Form.Control className={'d-none'}
                                      name="Villa"
                                      type="text"
                                      value={villa}
                                      required
                                      readOnly/>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={props.classes.caption}>{t('arrival')}</Form.Label>
                                <DatePickerInput onChange={anreiseChangeHandler}
                                                 name="Anreise"
                                                 value={anreise}
                                                 className={props.classes.datePicker}/>
                                <div className={'d-sm-none d-xs-display'} style={{height: 20}}/>
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={props.classes.caption}>{t('departure')}</Form.Label>
                                <DatePickerInput onChange={abreiseChangeHandler}
                                                 name="Abreise"
                                                 value={abreise}
                                                 className={props.classes.datePicker}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Form.Label className={props.classes.caption}>{t('name')}</Form.Label>
                                <Form.Control className={props.classes.p} type="text"
                                              name="Name"
                                              value={name}
                                              onChange={(e) => setName(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Form.Label className={props.classes.caption}>{t('mail')}</Form.Label>
                                <Form.Control className={props.classes.p} type="email"
                                              name="Mail"
                                              value={email}
                                              onChange={(e) => setEmail(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Form.Label className={props.classes.caption}>{t('phone')}</Form.Label>
                                <Form.Control className={props.classes.p} type="text"
                                              name="Telefonnummer"
                                              value={phone}
                                              onChange={(e) => setPhone(e.target.value)}
                                              required/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <FormCheck>
                                        <FormCheck.Input type="checkbox" required/>
                                        <FormCheck.Label className={props.classes.p}
                                                         style={{fontSize: '13px', lineHeight: '16px'}}>{t('dsgvoText')} <Link to={`/${language}/data-privacy`}>{t('dsgvoLinktext')}</Link>.
                                            *</FormCheck.Label>
                                    </FormCheck>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <div data-netlify-recaptcha="true"/>
                                <Button style={{width: '100%'}} type={'submit'}>{t('submit')}</Button>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
            )}
        </NetlifyForm>}
    </div>;
};

AnfrageFormular.defaultProps = {
    classes: {},
    villa: '',
    formularName: 'anfrage',
    fixed: false,
    successHandler: () => {
    },
};

export default AnfrageFormular;
