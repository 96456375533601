import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from 'rc-slider';
import {createUseStyles} from 'react-jss';
import {FaBath, FaBed, FaDollarSign} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import 'rc-slider/assets/index.css';
import './rangeslider.css';

import {setFilter as setFilterRedux, setPage as setPageRedux} from '../../actions';
import {mixins} from '../../tools/styles';
import CustomCurrency from '../../components/common/CustomCurrency';
import InputText from '../../components/common/InputText';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const useStyles = createUseStyles({
    caption: {
        ...mixins.caption,
    },
    p: {
        ...mixins.p,
    },
    unboxed: {
        ...mixins.unboxed,
    },
});

const Filter = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas']);
    const [filter, setFilter] = useState(props.filter);
    const [minMax, setMinMax] = useState({});
    const [bedsMinMaxObject, setBedsMinMaxObject] = useState({});
    const [bathsMinMaxObject, setBathsMinMaxObject] = useState({});

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);

    useEffect(() => {
        const filterChangeObject = {};

        if (props.minMax.beds || props.minMax.baths) {
            setMinMax(props.minMax);
        }
        if (props.minMax.beds) {
            const newBedsMinMaxObject = {};

            for (let i = props.minMax.beds.min; i <= props.minMax.beds.max; i++) {
                newBedsMinMaxObject[i] = i;
            }
            setBedsMinMaxObject(newBedsMinMaxObject);
            if (filter.beds.length === 0) {
                filterChangeObject.beds = [props.minMax.beds.min, props.minMax.beds.max];
            }
        }
        if (props.minMax.baths) {
            const newBathsMinMaxObject = {};

            for (let i = props.minMax.baths.min; i <= props.minMax.baths.max; i++) {
                newBathsMinMaxObject[i] = i;
            }
            setBathsMinMaxObject(newBathsMinMaxObject);
            if (filter.baths.length === 0) {
                filterChangeObject.baths = [props.minMax.baths.min, props.minMax.baths.max];
            }
        }

        if (filterChangeObject.baths || filterChangeObject.beds) {
            props.setFilterRedux({
                ...filter,
                ...filterChangeObject,
            });
        }
    }, [props.minMax]);

    const changeBathsHandler = (value) => {
        props.setFilterRedux({
            ...filter,
            baths: value,
        });
        props.setPageRedux(1);
    };
    const changeBedsHandler = (value) => {
        props.setFilterRedux({
            ...filter,
            beds: value,
        });
        props.setPageRedux(1);
    };
    const changeRentHandler = (value) => {
        props.setFilterRedux({
            ...filter,
            rent: value,
        });
        props.setPageRedux(1);
    };

    const changeTextHandler = (event) => {
        props.setFilterRedux({
            ...filter,
            text: event.currentTarget.value,
        });
        props.setPageRedux(1);
    };

    return <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
                <Col xs={12}>
                    <Card.Body style={{padding: 0, paddingRight: 30,}}>
                        <Form.Group>
                            <InputText className={classes.p} type="text" value={filter.text}
                                          onChange={changeTextHandler}
                                          placeholder={t('filterText')}/>
                        </Form.Group>
                        <br/>
                        <Form.Group>
                            <Form.Label className={classes.caption}>
                                <FaDollarSign size={'1.5em'}
                                              style={{marginLeft: 0,}}/>{t('weeklyRent')} <small>(<CustomCurrency
                                value={filter.rent[0]} prefix={'$'}/> {t('to')} <CustomCurrency value={filter.rent[1]}
                                                                                          prefix={'$'}/>)</small>
                            </Form.Label>
                            <div>
                                <Range className={classes.p} step={100} min={1000} max={8000} marks={{
                                    2000: <CustomCurrency value={2000} prefix={'$'}/>,
                                    3000: <CustomCurrency value={3000} prefix={'$'}/>,
                                    4000: <CustomCurrency value={4000} prefix={'$'}/>,
                                    5000: <CustomCurrency value={5000} prefix={'$'}/>,
                                    6000: <CustomCurrency value={6000} prefix={'$'}/>,
                                    7000: <CustomCurrency value={7000} prefix={'$'}/>,
                                }}
                                       onChange={changeRentHandler}
                                       value={filter.rent}/>
                            </div>
                        </Form.Group>
                        <div style={{marginTop: 50}}/>
                        <Form.Group>
                            <Form.Label className={classes.caption}>
                                <FaBed size={'1.5em'} style={{marginLeft: 0,}}/> {t('bedrooms')}
                            </Form.Label>
                            {minMax.beds &&
                            <Range className={classes.p} min={minMax.beds.min} max={minMax.beds.max}
                                   marks={bedsMinMaxObject}
                                   onChange={changeBedsHandler} value={filter.beds}/>
                            }
                        </Form.Group>
                        <div style={{marginTop: 50}}/>
                        <Form.Group>
                            <Form.Label className={classes.caption}>
                                <FaBath size={'1.5em'} style={{marginLeft: 0}}/> {t('baths')}
                            </Form.Label>
                            {minMax.baths && <Range className={classes.p} min={minMax.baths.min} max={minMax.baths.max}
                                                    marks={bathsMinMaxObject}
                                                    onChange={changeBathsHandler}
                                                    value={filter.baths}/>
                            }
                        </Form.Group>
                    </Card.Body>
                </Col>
            </Row>
        </Form>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterRedux: (filter) => {
            dispatch(setFilterRedux(filter));
        },
        setPageRedux: (page) => {
            dispatch(setPageRedux(page));
        },
    };
};

export default connect(null, mapDispatchToProps)(Filter);
