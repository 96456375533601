import axios from 'axios';
import {createClient} from 'contentful';

export const SET_PAGE = 'SET_PAGE';
export const SET_FILTER = 'SET_FILTER';
export const SET_BLOG_FILTER = 'SET_BLOG_FILTER';
export const FETCH_VILLEN = 'FETCH_VILLEN';
export const FETCH_DATENSCHUTZ = 'FETCH_DATENSCHUTZ';
export const FETCH_IMPRESSUM = 'FETCH_IMPRESSUM';
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const FETCH_FAQ = 'FETCH_FAQ';
export const MERKE_VILLA = 'MERKE_VILLA';
export const ENTMERKE_VILLA = 'ENTMERKE_VILLA';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const changeLanguage = (language) => ({
    type: CHANGE_LANGUAGE,
    payload: language,
});

export const merkeVilla = (slug) => ({
    type: MERKE_VILLA,
    payload: slug,
});

export const entmerkeVilla = (slug) => ({
    type: ENTMERKE_VILLA,
    payload: slug,
});

export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page,
});

export const setFilter = (filter) => ({
    type: SET_FILTER,
    payload: filter,
});

export const setBlogFilter = (filter) => ({
    type: SET_BLOG_FILTER,
    payload: filter,
});

const API_BASE_URL = 'https://cdn.contentful.com';

const DE_API_SPACE_ID = 'bajck5xc2vb5';
const DE_API_TOKEN = 'EXSHXzRU2xZ-iNP5c2F3Ola5b6bROAQfYOx5vnjhe7w';

const EN_API_SPACE_ID = 'fia59m14ql9d';
const EN_API_TOKEN = 'lRwqeTVKQExVO_DWKGFK7XeGugCsRnzMwzPQKmiWrLM';

const contentfulClientDe = createClient({
    space: DE_API_SPACE_ID,
    accessToken: DE_API_TOKEN,
    host: API_BASE_URL,
});

const contentfulClientEn = createClient({
    space: EN_API_SPACE_ID,
    accessToken: EN_API_TOKEN,
    host: API_BASE_URL,
});

export const fetchVillen = (language) => {
    let API_SPACE_ID = '';
    let API_TOKEN = '';

    if (language === 'de') {
        API_SPACE_ID = DE_API_SPACE_ID;
        API_TOKEN = DE_API_TOKEN;
    } else {
        API_SPACE_ID = EN_API_SPACE_ID;
        API_TOKEN = EN_API_TOKEN;
    }
    const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=villa`);
    return {
        type: FETCH_VILLEN,
        payload: request,
    };
};

export const fetchDatenschutz = (language) => {
    let API_SPACE_ID = '';
    let API_TOKEN = '';

    if (language === 'de') {
        API_SPACE_ID = DE_API_SPACE_ID;
        API_TOKEN = DE_API_TOKEN;
    } else {
        API_SPACE_ID = EN_API_SPACE_ID;
        API_TOKEN = EN_API_TOKEN;
    }
    const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=datenschutz`);
    return {
        type: FETCH_DATENSCHUTZ,
        payload: request,
    };
};

export const fetchImpressum = (language) => {
    let API_SPACE_ID = '';
    let API_TOKEN = '';

    if (language === 'de') {
        API_SPACE_ID = DE_API_SPACE_ID;
        API_TOKEN = DE_API_TOKEN;
    } else {
        API_SPACE_ID = EN_API_SPACE_ID;
        API_TOKEN = EN_API_TOKEN;
    }
    const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=impressum`);
    return {
        type: FETCH_IMPRESSUM,
        payload: request,
    };
};

export const fetchDashboard = (language) => {
    let API_SPACE_ID = '';
    let API_TOKEN = '';

    if (language === 'de') {
        API_SPACE_ID = DE_API_SPACE_ID;
        API_TOKEN = DE_API_TOKEN;
    } else {
        API_SPACE_ID = EN_API_SPACE_ID;
        API_TOKEN = EN_API_TOKEN;
    }
    const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=dashboard`);
    return {
        type: FETCH_DASHBOARD,
        payload: request,
    };
};

// export const fetchBlog = (language) => {
//     let API_SPACE_ID = '';
//     let API_TOKEN = '';
//
//     if(language === 'de') {
//         API_SPACE_ID = DE_API_SPACE_ID;
//         API_TOKEN = DE_API_TOKEN;
//     } else {
//         API_SPACE_ID = EN_API_SPACE_ID;
//         API_TOKEN = EN_API_TOKEN;
//     }
//     const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=blog`);
//     return {
//         type: FETCH_BLOG,
//         payload: request,
//     };
// };

export const fetchBlog = (language) => {
    const config = {
        content_type: 'blog',
        include: 10,
        order: '-fields.date'
    };

    if(language === 'de') {
        const request = contentfulClientDe.getEntries(config);
        return {
            type: FETCH_BLOG,
            payload: request
        }
    } else {
        const request = contentfulClientEn.getEntries(config);
        return {
            type: FETCH_BLOG,
            payload: request
        }
    }
};

export const fetchAuthors = (language) => {
    const config = {
        content_type: 'autor',
        include: 10,
    };

    if(language === 'de') {
        const request = contentfulClientDe.getEntries(config);
        return {
            type: FETCH_AUTHORS,
            payload: request
        }
    } else {
        const request = contentfulClientEn.getEntries(config);
        return {
            type: FETCH_AUTHORS,
            payload: request
        }
    }
};

export const fetchFaq = (language) => {
    let API_SPACE_ID = '';
    let API_TOKEN = '';

    if (language === 'de') {
        API_SPACE_ID = DE_API_SPACE_ID;
        API_TOKEN = DE_API_TOKEN;
    } else {
        API_SPACE_ID = EN_API_SPACE_ID;
        API_TOKEN = EN_API_TOKEN;
    }
    const request = axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=faq`);
    return {
        type: FETCH_FAQ,
        payload: request,
    };
};
