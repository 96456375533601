import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {createUseStyles} from 'react-jss';
import {Helmet} from 'react-helmet';
import Lightbox from 'react-image-lightbox';
import {useTranslation} from 'react-i18next';

import 'react-image-lightbox/style.css';

import './rc-datepicker.css';

import {fetchVillen, merkeVilla, entmerkeVilla} from '../../../actions';
import {colors, fonts, tools, mixins} from '../../../tools/styles';
import DetailMap from '../../../components/villen/DetailMap';
import BackLink from '../../../components/common/BackLink';
import TopNavBar from '../../../components/common/TopNavBar';
import HeadSpace from '../../../components/common/HeadSpace';
import Placeholder from '../../../components/common/Placeholder';
import YoutubeVideo from '../../../components/common/YoutubeVideo';
import VillenMeta from '../../../components/villen/VillenMeta';
import CustomMarkdown from '../../../components/common/CustomMarkdown';
import AnfrageFormular from './AnfrageFormular';
import SocialShare from '../../../components/common/SocialShare';
import MerkToggle from '../../../components/common/MerkToggle';
import HeightSpacer from '../../../components/common/HeightSpacer';
import {getLanguageOfLocation} from '../../../tools/tools';
import VillenFilterMeta from '../../../components/villen/VillenFilterMeta';

const useStyles = createUseStyles({
    datePicker: {
        fontSize: 16,
        lineHeight: '22px',
        fontFamily: fonts.primary,
    },
    h3: {
        ...mixins.h3,
    },
    h4: {
        ...mixins.h4,
    },
    caption: {
        ...mixins.caption,
    },
    captionSecondaryColor: {
        ...mixins.caption,
        color: colors.secondary,
    },
    unboxed: {
        ...mixins.unboxed,
    },
    p: {
        ...mixins.p,
    },
    warning: {
        ...mixins.p,
        color: colors.secondary,
    },
    success: {
        ...mixins.p,
        color: colors.vibrate,
    },
    galleryBox: {
        transition: tools.transition,
        margin: 10,
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    galleryTextBox: {
        margin: 10,
        padding: 20,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: tools.borderRadius,
        boxShadow: tools.boxShadow,
        height: 100,
        fontWeight: 'bold',
        fontFamily: fonts.secondary,
        fontSize: 18,
        letterSpacing: 0,
        color: colors.secondary,
        verticalAlign: 'center',
    },
    box: {
        boxShadow: tools.boxShadow,
    },
    bigImage: {
        boxShadow: tools.boxShadow,
        borderRadius: tools.borderRadius,
    },
    formular: {
        boxShadow: tools.boxShadow,
        padding: 30,
        zIndex: 900,
        backgroundColor: 'white',
        borderRadius: tools.borderRadius,
    },
});

const VillaDetail = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas']);

    const [villa, setVilla] = useState({});
    const [image, setImage] = useState({});
    const [isGalleryCollapsed, setIsGalleryCollapsed] = useState(true);
    const [imageArray, setImageArray] = useState([]);
    const [lightBoxIndex, setLightBoxIndex] = useState(0);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

    const [merkliste, setMerkliste] = useState({});
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);
    const toggleMerkliste = (slug) => {
        if (!!merkliste[slug]) {
            props.entmerkeVilla(slug);
        } else {
            props.merkeVilla(slug);
        }
    };

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchVillen(language);
    }, [props.location]);

    useEffect(() => {
        props.villen.map((item, index) => {
            if (item.fields.slug === props.match.params.slug) {
                setVilla(item);
                setImage(item.fields.images[0]);
            }
        });
    }, [props.villen]);

    useEffect(() => {
        if (!villa.fields) {
            return;
        }

        const newImageArray = [];

        villa.fields.images.map((item) => {
            newImageArray.push(`${item.fields.file.url}?w=1800`);
        });
        setImageArray(newImageArray);
    }, [villa]);

    const formSubmitSuccess = () => {
        props.history.push(`/${props.language.active}/thank-you-booking/`);
    };

    return <>
        <Helmet>
            <title>{villa.fields && `${villa.fields.caption} | Villas of Paradise`}</title>
            {villa.fields && <meta name="title" content={`${villa.fields.metaTitel || villa.fields.caption}`}/>}
            {villa.fields &&
            <meta name="description" content={`${villa.fields.metaDescription || villa.fields.text}`}/>}
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <Container>
            <Row>
                <Col xl={8}>
                    <Row>
                        <Col xs={6}>
                            <BackLink language={props.language.active}/>
                        </Col>
                        {villa.fields && <Col className={'text-right'} xs={6}>
                            <MerkToggle isGemerkt={!!merkliste[villa.fields.slug]}
                                        onClick={() => toggleMerkliste(villa.fields.slug)}
                                        language={props.language.active}/>
                        </Col>}
                    </Row>
                </Col>
            </Row>
            {villa.fields && <Row>
                <Col xl={8}>
                    <Row>
                        <Col md={8}>
                            <h1 className={classes.h3}>{villa.fields.caption}</h1>
                            <HeightSpacer/>
                        </Col>
                        <Col md={4}>
                            <div className={'d-sm-flex d-md-none'}>
                                <HeightSpacer/>
                            </div>

                            <SocialShare language={props.language.active}/>
                        </Col>
                    </Row>

                    <div className={'d-md-none'}>
                        <br/>
                    </div>

                    <Row>
                        <Col>
                            <VillenMeta villa={villa} onlyWeeklyCosts={true} language={props.language.active}/>
                        </Col>
                    </Row>

                    <br/>
                    {image.fields &&
                    <img className={classes.bigImage} alt={villa.fields.caption} src={`${image.fields.file.url}?w=1000`}
                         onClick={() => {
                             setIsLightBoxOpen(true);
                             setLightBoxIndex(0);
                         }} style={{width: '100%'}}/>}
                    <br/><br/>
                    <Row noGutters>
                        {villa.fields.images.map((item, index) => {
                            return <Fragment key={index}>
                                {index <= 4 && <Col xs={6} sm={4} md={3} lg={2}>
                                    <div className={classes.galleryBox} onClick={() => {
                                        setIsLightBoxOpen(true);
                                        setLightBoxIndex(index);
                                    }}><Placeholder
                                        image={`${item.fields.file.url}?w=200`} h={100}/></div>
                                </Col>}
                                {isGalleryCollapsed && index === 5 && <Col xs={6} sm={4} md={3} lg={2}>
                                    <div className={classes.galleryTextBox}
                                         onClick={() => setIsGalleryCollapsed(false)}>{t('showAll')}
                                    </div>
                                </Col>}
                                {!isGalleryCollapsed && index === 5 && <Col xs={6} sm={4} md={3} lg={2}>
                                    <div className={classes.galleryTextBox}
                                         onClick={() => setIsGalleryCollapsed(true)}>{t('showLess')}
                                    </div>
                                </Col>}

                                {!isGalleryCollapsed && index > 4 && <Col xs={6} sm={4} md={3} lg={2}>
                                    <div className={classes.galleryBox} onClick={() => {
                                        setIsLightBoxOpen(true);
                                        setLightBoxIndex(index);
                                    }}><Placeholder
                                        image={`${item.fields.file.url}?w=200`} h={100}/></div>
                                </Col>}
                            </Fragment>;
                        })}
                    </Row>
                    <br/>
                    {isLightBoxOpen && (
                        <Lightbox
                            mainSrc={imageArray[lightBoxIndex]}
                            nextSrc={imageArray[(lightBoxIndex + 1) % imageArray.length]}
                            prevSrc={imageArray[(lightBoxIndex + imageArray.length - 1) % imageArray.length]}
                            onCloseRequest={() => setIsLightBoxOpen(false)}
                            onMovePrevRequest={() =>
                                setLightBoxIndex((lightBoxIndex + imageArray.length - 1) % imageArray.length)
                            }
                            onMoveNextRequest={() =>
                                setLightBoxIndex((lightBoxIndex + 1) % imageArray.length)
                            }
                        />
                    )}
                    <br/>
                    <h2 className={classes.h4}>{t('facilities')}</h2>
                    <div>
                        <VillenFilterMeta villa={villa} language={props.language.active}/>
                    </div>
                    <br/>
                    <h2 className={classes.h4}>{t('description')}</h2>
                    <div className={classes.unboxed}>
                        <CustomMarkdown source={villa.fields.text}/>
                    </div>
                    <br/>
                    {villa.fields.youtubeId && <>
                        <h2 className={classes.h4}>{t('video')}</h2>
                        <div className={classes.box}>
                            <YoutubeVideo id={villa.fields.youtubeId}/>
                        </div>

                        <br/>
                    </>}
                    <h2 className={classes.h4}>{t('location')}</h2>

                    <div className={classes.box}>
                        <DetailMap villa={villa} language={props.language.active}/>
                    </div>

                </Col>
                <Col>
                    <div className={'d-none d-lg-block'} style={{height: '100%'}}>
                        <AnfrageFormular formularName={'anfrage'} classes={classes} villa={villa.fields.caption}
                                         fixed={true} successHandler={formSubmitSuccess}
                                         language={props.language.active}/>
                    </div>
                    <div className={'d-md-flex d-lg-none'}>
                        <AnfrageFormular formularName={'anfrage-mobil'} classes={classes} villa={villa.fields.caption}
                                         fixed={false} successHandler={formSubmitSuccess}
                                         language={props.language.active}/>
                    </div>
                </Col>
            </Row>}
        </Container>;
    </>;
};

const mapStateToProps = (state) => {
    return {
        villen: state.villen,
        merkliste: state.merkliste,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchVillen: (language) => {
            dispatch(fetchVillen(language));
        },
        merkeVilla: (slug) => {
            dispatch(merkeVilla(slug));
        },
        entmerkeVilla: (slug) => {
            dispatch(entmerkeVilla(slug));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VillaDetail));
