import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import {Accordion} from 'react-accessible-accordion';

import CustomSpinner from '../../components/common/CustomSpinner';
import {fetchFaq} from '../../actions';
import FaqItem from './item';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import {mixins} from '../../tools/styles';
import {getLanguageOfLocation} from '../../tools/tools';

const useStyles = createUseStyles({
    h4: {
        ...mixins.h4,
    },
    h3: {
        ...mixins.h3,
    },
    caption: {
        ...mixins.caption,
    },
    unboxed: {
        ...mixins.unboxed,
    },
    p: {
        ...mixins.p,
    },
    figureImage: {
        marginBottom: 10,
        width: '100%',
    },
});

const FAQ = (props) => {
    const classes = useStyles();

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchFaq(language);
    }, [props.location]);

    const [faq, setFaq] = useState([]);

    useEffect(() => {
        if (props.faq.length > 0) {
            let items = [...props.faq];
            items.sort((a, b) => {
                return a.fields.id - b.fields.id;
            });
            setFaq(items);
        }
    }, [props.faq]);

    return <>
        <Helmet>
            <title>FAQ | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <Container>
            <Row>
                <Col md={12}>
                    <h1 className={classes.h3}>FAQ</h1>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={12}>
                    {faq.length > 0 ? <Accordion allowZeroExpanded>
                        {faq.map((item, index) => {
                            return <FaqItem key={index} item={item} index={index} classes={classes}/>;
                        })}
                    </Accordion> : <CustomSpinner/>}
                </Col>
            </Row>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        faq: state.faq,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchFaq: (language) => {
            dispatch(fetchFaq(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
