import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container';
import {createUseStyles} from 'react-jss';
import {Helmet} from 'react-helmet';
import {isMobileOnly} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {fetchBlog, fetchDashboard, fetchVillen} from '../../actions';
import {mixins} from '../../tools/styles';
import Header from './components/Header';
import HeadSpace from '../../components/common/HeadSpace';
import BlogTeaserList from './components/BlogTeaserList';
import TopTeaser from './components/TopTeaser';
import VillenTeaserList from './components/VillenTeaserList';
import MidTeaser from './components/MidTeaser';
import UspTeaser from './components/UspTeaser';
import {getLanguageOfLocation} from '../../tools/tools';

const useStyles = createUseStyles({
    h1: {
        ...mixins.h1,
    },
    h3: {
        ...mixins.h3,
    },
    caption: {
        ...mixins.caption,
    },
    unboxed: {
        ...mixins.unboxed,
        margin: '40px 0',
    },
    p: {
        ...mixins.p,
        margin: '40px 0',
    },
    figureImage: {
        marginBottom: 10,
        width: '100%',
    },
});

const Dashboard = (props) => {

    const { t } = useTranslation(['general']);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchBlog(language);
        props.fetchVillen(language);
        props.fetchDashboard(language);
    }, [props.location]);

    const [villen, setVillen] = useState(props.villen || []);
    useEffect(() => {
        if (props.villen.length > 0) {
            setVillen(props.villen);
        }
    }, [props.villen]);

    const [blog, setBlog] = useState(props.blog || []);
    useEffect(() => {
        if (props.blog.length > 0) {
            setBlog(props.blog);
        }
    }, [props.blog]);

    const [meta, setMeta] = useState(props.meta || []);
    useEffect(() => {
        if (props.meta && props.meta.dashboard && props.meta.dashboard.length > 0) {
            setMeta(props.meta);
        }
    }, [props.meta]);

    const classes = useStyles();

    const mobileHeight = 110;
    const regularHeight = 160;

    return <div>
        <Helmet>
            <title>{t('general:browserTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        {meta && meta.dashboard && meta.dashboard.length > 0 &&
        <Header image={`${meta.dashboard[0].fields.headerImage.fields.file.url}?w=${isMobileOnly ? 1200 : 2000}`}
                h={isMobileOnly ? 400 : 700}/>}

        {meta && meta.dashboard && meta.dashboard.length > 0 && <>
            <Container>
                <TopTeaser classes={classes} content={meta.dashboard[0]} language={props.language.active}/>
            </Container>
            <HeadSpace h={isMobileOnly ? mobileHeight : regularHeight}/>
        </>}

        {meta && meta.dashboard && meta.dashboard.length > 0 && <>
            <Container>
                <UspTeaser classes={classes} content={meta.dashboard[0]}/>
            </Container>
            <HeadSpace h={isMobileOnly ? mobileHeight : regularHeight}/>
        </>}

        <Container>
            <VillenTeaserList villen={villen} classes={classes} language={props.language.active}/>
        </Container>
        <HeadSpace h={isMobileOnly ? mobileHeight - 30 : regularHeight - 30}/>

        {meta && meta.dashboard && meta.dashboard.length > 0 && <>
            <Container>
                <MidTeaser classes={classes} content={meta.dashboard[0]} language={props.language.active}/>
            </Container>
            <HeadSpace h={isMobileOnly ? mobileHeight : regularHeight}/>
        </>}

        <Container>
            <BlogTeaserList blog={blog} classes={classes} language={props.language.active}/>
        </Container>
    </div>;
};

const mapStateToProps = (state) => {
    return {
        villen: state.villen,
        blog: state.blog,
        meta: state.meta,
        language: state.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVillen: (language) => {
            dispatch(fetchVillen(language));
        },
        fetchBlog: (language) => {
            dispatch(fetchBlog(language));
        },
        fetchDashboard: (language) => {
            dispatch(fetchDashboard(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
