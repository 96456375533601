import React from 'react';
import {createUseStyles} from 'react-jss';

import {breakpoints} from '../../tools/styles';

const HeadSpace = (props) => {
    const useStyles = createUseStyles({
        headSpace: {
            height: props.h,
            [`@media (max-width: ${breakpoints.mdMax}px)`]: {
                height: props.h - 20,
            },
        },
    });

    const classes = useStyles();
    return <div className={classes.headSpace}/>;
};
HeadSpace.defaultProps = {
    h: 110,
};

export default HeadSpace;
