import React from 'react';
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa';

import Feature from './Feature';

const FeatureMeta = (props) => {

    return <Feature {...props} icon={props.isActive ? <FaCheckCircle/> : <FaTimesCircle/>} />
};

FeatureMeta.defaultProps = {
    isButton: false,
    isDisabled: false,
    isActive: false,
    text: '',
    onClick: () => {

    },
    variant: 'secondary',
};

export default FeatureMeta;

