import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';

import {tools} from '../../tools/styles';

const Placeholder = (props) => {

    const [image, setImage] = useState('');

    useEffect(() => {
        setImage(props.image);
    }, [props.image]);

    const useStyles = createUseStyles({
        bgImg: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            boxShadow: tools.boxShadow,
            borderRadius: tools.borderRadius,
            height: props.h,
            width: '100%',
        },
    });

    const classes = useStyles();

    return <div className={props.className} onClick={props.onClick}>
        {props.placeholder ?
            <img src={props.placeholder} className={classes.bgImg} style={{backgroundImage: `url(${image})`}}
                 alt={''}/>
            :
            <div style={{backgroundImage: `url(${image})`}} className={classes.bgImg}/>}
    </div>;

};
Placeholder.defaultProps = {
    h: 'auto',
    onClick: () => {
    },
};

export default Placeholder;
