import React from 'react';

const VillaAddress = (props) => {
    return props.address.split(' ').slice(1).join(' ');
};

VillaAddress.defaultProps = {
    address: '',
};

export default VillaAddress;
