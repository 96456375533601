import React, {useEffect, useState} from 'react';

import TopNavBar from '../../../components/common/TopNavBar';
import {useScroll} from '../../../tools/effects';
import HeaderImage from '../../../components/common/HeaderImage';

const Header = (props) => {
    const [image, setImage] = useState(props.image);
    useEffect(() => {
        setImage(props.image);
    }, [props.image]);

    const [height, setHeight] = useState(props.h);
    useEffect(() => {
        setHeight(props.h);
    }, [props.h]);

    const [isScrollHeightReached, setIsScrollHeightReached] = useState(false);
    let scroll = useScroll();

    useEffect(() => {
        if (scroll.y >= 300) {
            setIsScrollHeightReached(false);
        } else {
            setIsScrollHeightReached(true);
        }
    }, [scroll]);

    return <>
        <HeaderImage
            image={image}
            h={height}
            waveFactor={8}
            correction={3}
        />
        <TopNavBar transparent={isScrollHeightReached}/>
    </>;
};

Header.defaultProps = {
    image: '',
    h: 800,
};

export default Header;
