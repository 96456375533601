export const cleverreachPlusEn = `
<form class="layout_form cr_form_dark cr_font" action="https://eu2.cleverreach.com/f/238854-238416/wcs/" method="post" target="_blank">
	<div class="cr_body cr_page cr_font formbox">
		<div class="non_sortable"></div>

    <div class="editable_content">
      <div id="5134564" rel="text" class="cr_ipe_item ui-sortable mb-3" >
        <label for="text5134564" class="itemname form-label">First name and last name*</label>
        <input id="text5134564" class="form-control" name="1138628" type="text" value="" required/>
      </div>

      <div id="5133736" rel="email" class="cr_ipe_item ui-sortable musthave mb-3" >
        <label for="text5133736" class="itemname form-label">Mail*</label>
        <input id="text5133736" class="form-control" name="email" value="" type="email" required/>
      </div>

			<div class="form-group form-check">
				<input type="checkbox" class="form-check-input" id="dsgvo" required>
				<label class="form-check-label" for="dsgvo">I have read the notes on privacy and accept them. <a href="/en/data-privacy">data privacy</a>. *</label>
			</div>

      <div id="5045642" rel="button" class="cr_ipe_item ui-sortable submit_container" style="width: 100%;" >
        <button type="submit" class="btn btn-primary" style="width: 100%;">Get tips</button>
      </div>
    </div>
  </div>
</form>

<style>
  .cr_form_dark label {
    color: #0D1822;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: Poppins;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: 0;
  }

  .cr_form_dark .btn-primary {
    color: white;
    border: 2px solid;
    padding: 7px 40px;
    overflow: hidden;
    font-size: 18px;
    box-shadow: 0px 3px 6px #00000029;
    transition: background-position 1s ease,color 1s ease;
    font-family: Poppins;
    font-weight: bold;
    line-height: 27px;
    border-color: #E65759;
    border-radius: 5px;
    letter-spacing: 0;
    background-color: #E65759;
    background-image: url(/images/wave-button-white.svg);
    background-repeat: repeat-x;
    background-position: 0 65px;
  }

  .cr_form_dark .btn-primary:hover {
    color: #E65759;
    background-position: 0 -20px;
  }

	.cr_form_dark .form-check label {
		font-family: "Playfair Display";
		font-size: 13px;
		line-height: 16px;
		font-weight: 400;
	}

	.cr_form_dark .form-check .form-check-input {
		margin-top: 2px;
	}
</style>

`
