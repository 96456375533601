import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {GoogleMap, InfoWindow, Marker, useLoadScript} from '@react-google-maps/api';
import {Link} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import Spinner from '../../common/CustomSpinner';
import {mixins, tools} from '../../../tools/styles';
import Placeholder from '../../common/Placeholder';
import VillaPropsOneline from '../../common/VillaPropsOneline';

const useStyles = createUseStyles({
    box: {
        boxShadow: tools.boxShadow,
    },
    h4: {
        ...mixins.h4,
    },
    p: {
        ...mixins.p,
    },
    figureImage: {
        marginBottom: 10,
        width: '100%',
    },
});

const VillenMap = (props) => {
    const classes = useStyles();
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyBhfS3_jCKbw_ZnYpT3SlmO4FMV4wpf7cY',
    });

    const [showInfo, setShowInfo] = useState([]);
    const [activePin, setActivePin] = useState(0);

    const handler = (index) => {
        const array = [];
        props.villen.map((item, innerIndex) => {
            if (index === innerIndex) {
                array.push(true);
            } else {
                array.push(false);
            }
        });

        setShowInfo(array);
        setActivePin(index);
    };

    useEffect(() => {
        const array = [];
        props.villen.map(item => {
            array.push(false);
        });
        setShowInfo(array);
    }, [props.villen]);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const onLoad = useCallback(
        function onLoad(mapInstance) {
            // do something with map Instance
        }
    );

    const renderMap = () => {
        return <>{props.villen.length > 0 && <div className={classes.box}><GoogleMap
            mapContainerStyle={{height: '550px', width: '100%',}}
            onLoad={onLoad}
            zoom={10}
            center={{
                lat: props.villen[activePin].fields.coordinates.lat,
                lng: props.villen[activePin].fields.coordinates.lon,
            }}>
            {props.villen.map((villa, index) => {
                return <Fragment key={index}>
                    {activePin === index && showInfo[index] && <InfoWindow
                        position={{lat: villa.fields.coordinates.lat, lng: villa.fields.coordinates.lon}}
                        onCloseClick={() => handler(index)}>
                        <div style={{background: `white`, padding: 15,}}>
                            <div>
                                <h5 className={classes.h4}><Link
                                    to={`/${language}/villas/${villa.fields.slug}`}>{villa.fields.caption}</Link>
                                </h5>
                                <div className={classes.p}>
                                    <Link to={`/${language}/villas/${villa.fields.slug}`}>
                                        <Placeholder className={classes.figureImage}
                                                     image={`${villa.fields.images[0].fields.file.url}?w=400&fit=thumb`}
                                                     h={100}/>
                                    </Link>
                                    <VillaPropsOneline villa={villa}/>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>}
                    <Marker
                        position={{
                            lat: villa.fields.coordinates.lat,
                            lng: villa.fields.coordinates.lon,
                        }}
                        onClick={() => handler(index)}
                        title={villa.fields.caption}
                    />
                </Fragment>;
            })}

        </GoogleMap></div>}
        </>;
    };

    if (loadError) {
        return <div className={classes.p}>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <Spinner/>;
};

VillenMap.defaultProps = {
    language: '',
    villen: [],
};

export default VillenMap;
