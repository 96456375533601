import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import {createUseStyles} from 'react-jss';

import Placeholder from '../../../components/common/Placeholder';
import {fonts, colors} from '../../../tools/styles';
import VillaPropsOneline from '../../../components/common/VillaPropsOneline';

const useStyles = createUseStyles({
    figureCaption: {
        fontFamily: fonts.primary,
        letterSpacing: 0,
        color: colors.muted,
        '& strong': {
            fontFamily: fonts.secondary,
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 0,
            color: colors.primary,
            margin: 0,
            padding: 0,
            lineHeight: 1,
        },
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

const VillenTeaserList = (props) => {
    const classes = useStyles();

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <>
        {props.villen.length > 0 &&
        <Row>
            {[...props.villen.slice(0, 4)].map((item, index) => {
                return <Col xs={12} lg={3} md={4} sm={6} key={index} style={{marginBottom: 30}}>
                    <Link to={`/${language}/villas/${item.fields.slug}`}>
                        <Placeholder className={props.classes.figureImage}
                                     image={`${item.fields.images[0].fields.file.url}?w=400&fit=thumb`} h={300}/>
                    </Link>
                    <Link to={`/${language}/villas/${item.fields.slug}`} className={classes.figureCaption}>
                        <Figure.Caption>
                            <strong>{item.fields.caption}</strong>
                            <br/>
                            <VillaPropsOneline villa={item} language={language}/>
                        </Figure.Caption>
                    </Link>
                </Col>;
            })}
        </Row>
        }
    </>;
};

VillenTeaserList.defaultProps = {
    language: '',
};

export default VillenTeaserList;
