import {SET_FILTER, SET_PAGE} from '../actions';

const defaultState = {
    page: 1,
    items_per_page: navigator.userAgent !== 'ReactSnap' ? 10 : 1000,
    filter: {
        text: '',
        beds: [],
        baths: [],
        rent: [1000, 8000],
        sortAsc: true,
        hasPool: false,
        hasPoolHeater: false,
        hasWhirlpool: false,
        hasGrill: false,
        hasGulfAccess: false,
        hasBoatLanding: false,
    },
};

const filter = (state = defaultState, action) => {
    const newState = {...state};

    switch (action.type) {
        case SET_PAGE:
            newState.page = action.payload;
            return newState;

        case SET_FILTER:
            newState.filter = action.payload;
            return newState;

        default:
            return state;
    }
};

export default filter;
