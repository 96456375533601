import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Button from '../../../components/common/Button';

const TopTeaser = (props) => {
    const { t } = useTranslation(['dashboard']);

    const [content, setContent] = useState(props.content);
    useEffect(() => {
        setContent(props.content);
    }, [props.content]);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <Row>
        <Col md={{span: 10, offset: 1}}>
            <h1 className={props.classes.h1}>{content.fields.topTeaserCaption}</h1>
            <p className={props.classes.unboxed}>
                {content.fields.topTeaserText}
            </p>
            <Link to={`/${language}/villas`}>
                <Button>{t('teaserCta')}</Button>
            </Link>
        </Col>
    </Row>;
};

TopTeaser.defaultProps = {
    content: {},
    language: '',
};

export default TopTeaser;
