import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';

const CustomCurrency = (props) => {
    const [value, setValue] = useState(props.value);
    const [prefix, setPrefix] = useState(props.prefix);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setPrefix(props.prefix);
    }, [props.prefix]);

    return <NumberFormat value={value} displayType={'text'}
                         thousandSeparator={'.'} decimalSeparator={','} prefix={prefix}/>;
};

CustomCurrency.defaultProps = {
    value: 0,
    prefix: '',
};

export default CustomCurrency;
