import {CHANGE_LANGUAGE} from '../actions';

const defaultState = {
    active: 'de',
};

const language = (state = defaultState, action) => {
    const newState = {...state};

    switch (action.type) {
        case CHANGE_LANGUAGE:
            newState.active = action.payload;
            return newState;

        default:
            return state;
    }
};

export default language;
