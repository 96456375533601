import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import CustomSpinner from '../../components/common/CustomSpinner';
import {fetchBlog} from '../../actions';
import Item from './Item';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import {mixins} from '../../tools/styles';
import CategoryHeader from './CategoryHeader';
import Filter from './Filter';
import {BlogCategories} from '../../enums/blogCategories';
import {getLanguageOfLocation} from '../../tools/tools';

const useStyles = createUseStyles({
    h3: {
        ...mixins.h3,
    },
});

const Blog = (props) => {

    const classes = useStyles();
    const {t} = useTranslation(['blog', 'general']);

    const [items, setItems] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [category, setCategory] = useState('all');

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchBlog(language);
    }, [props.location]);

    useEffect(() => {
        const categories = Object.values(BlogCategories);
        if (categories.includes(props.match.params.cat)) {
            setCategory(props.match.params.cat);
        } else {
            setCategory('all');
        }
    }, [props.match]);

    useEffect(() => {
        if (props.items.length > 0) {
            setItems(props.items);
        }
    }, [props.items]);

    useEffect(() => {
        const newFiltered = [];

        [...items].map(itm => {
            if (category !== 'all' && itm.fields.category !== category) {
                return;
            }

            if (props.filter.filter.text) {
                if (
                    itm.fields.titel.toLowerCase().search(props.filter.filter.text.trim().toLowerCase()) === -1
                    &&
                    itm.fields.text.toLowerCase().search(props.filter.filter.text.trim().toLowerCase()) === -1
                ) {
                    return;
                }
            }
            newFiltered.push(itm);
        });

        setFiltered(newFiltered);
    }, [category, items, props.filter]);

    const blogCategoryClickHandler = (paraCategory) => {
        if (paraCategory !== category) {
            props.history.push(`/${props.language.active}/blog/${paraCategory}`);
        } else {
            props.history.push(`/${props.language.active}/blog/all`);
        }
    };

    return <>
        <Helmet>
            <title>{t('general:browserTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <Container>
            <Row>
                <Col md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}} xl={{span: 6, offset: 3}}>
                    <h1 className={classes.h3} style={{textAlign: 'center',}}>{t('caption')}</h1>
                </Col>
            </Row>
        </Container>

        <Container>
            <Filter filter={props.filter}/>
        </Container>
        <Container>
            <CategoryHeader selectedCategory={category} blogCategoryClickHandler={blogCategoryClickHandler}
                            language={props.language.active}/>
        </Container>
        <br/>
        <br/>
        <Container>
            <Row>
                <Col md={12}>
                    {!items.length ? <CustomSpinner/> : <div>{
                        filtered.length > 0 && <div>
                            <Row className={'h-100'}>
                                {filtered.map((itm, index) => {
                                    return <Col md={4} sm={6} xs={12} key={index} style={{marginBottom: 30}}>
                                        <Item
                                            item={itm}
                                            language={props.language.active}
                                            category={category}/>
                                    </Col>;
                                })}
                            </Row></div>
                    }</div>}
                </Col>
            </Row>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        items: state.blog,
        filter: state.blogFilter,
        language: state.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBlog: (language) => {
            dispatch(fetchBlog(language));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Blog));
