import React, {useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {createUseStyles} from 'react-jss';

import {breakpoints, mixins, tools} from '../../../tools/styles';

const useStyles = createUseStyles({
    teaserArea: {
        margin: 15,
        backgroundColor: 'white',
        boxShadow: tools.boxShadow,
        borderRadius: 5,
        padding: '50px 20px',
        [`@media (max-width: ${breakpoints.mdMax}px)`]: {
            margin: 0,
            marginBottom: 20,
            padding: 20,
        },
    },
    caption: {
        ...mixins.caption,
    },
    p: {
        ...mixins.p,
        marginBottom: 0,
    },
    siegel: {
        position: 'absolute',
        top: -10,
        right: 5,
        height: 70,
        maxWidth: 'auto',
        [`@media (max-width: ${breakpoints.mdMax}px)`]: {
            display: 'none'
        },
    },
});

const UspTeaser = (props) => {

    const classes = useStyles();

    const [content, setContent] = useState(props.content);
    useEffect(() => {
        setContent(props.content);
    }, [props.content]);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);


    return <Row>
        <Col lg={4} className={classes.teaserAreaWrapper}>
            <div className={classes.teaserArea}>
                <img src="/images/siegel.png" alt="" className={classes.siegel}/>
                <h2 className={classes.caption}>
                    {content.fields.uspCaption1}
                </h2>
                <p className={classes.p}>
                    {content.fields.uspText1}
                </p>
            </div>
        </Col>
        <Col lg={4} className={classes.teaserAreaWrapper}>
            <div className={classes.teaserArea}>
                <img src="/images/siegel.png" alt="" className={classes.siegel}/>
                <h2 className={classes.caption}>
                    {content.fields.uspCaption2}
                </h2>
                <p className={classes.p}>
                    {content.fields.uspText2}
                </p>
            </div>
        </Col>
        <Col lg={4} className={classes.teaserAreaWrapper}>
            <div className={classes.teaserArea}>
                <img src="/images/siegel.png" alt="" className={classes.siegel}/>
                <h2 className={classes.caption}>
                    {content.fields.uspCaption3}
                </h2>
                <p className={classes.p}>
                    {content.fields.uspText3}
                </p>
            </div>
        </Col>
    </Row>;
};

UspTeaser.defaultProps = {
    content: {},
    language: '',
};

export default UspTeaser;
