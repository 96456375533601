import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from 'react';
import Button from '../components/common/Button';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    "en":{
        "general":{
            "browserTitle":"Find Villas & Holiday Houses in Cape Coral, Florida",
            "villas":"villas",
            "wishlist":"wish list",
            "blog":"blog",
            "contact":"contact",
            "faq":"faq",
            "imprint":"imprint",
            "dataPrivacy":"privacy policy",
            "goBack":"Back",
            "toHome":"Navigate to home",
        },
        "dashboard":{
            "teaserCta":"Show all villas"
        },
        "villas":{
            "pageCaption":"Find exactly, what you are looking for.",
            "baths":"baths",
            "bedrooms":"bedrooms",
            "filterText":"Search for anything ...",
            "weeklyRent":"Weekly rent",
            "perWeek":"weekly",
            "show":"Show",
            "of":"of",
            "to":"to",
            "availableVillas":"available villas",
            "more":"More ...",
            "addTo":"Add to wish list",
            "removeFrom":"Remove from list",
            "yourWishlist":"Your wishlist.",
            "description":"Description",
            "location":"Location",
            "video":"Video",
            "showAll":"Show all pictures",
            "showLess":"Show less pictures",
            "pool": "pool",
            "poolheater":"pool heater",
            "whirlpool":"whirlpool",
            "grill":"grill",
            "seaAccess":"sea access",
            "privatePier": "boat dock",
            "facilities": "Facilities",
            "moreFilters": "more filters",
            "emptyWishlist": "Your wish list is empty.",
        },
        "blog":{
            "tripgoals":"Trip goals",
            "florida":"About florida",
            "traveltips":"Travel tips",
            "readArticleCta":"Show article",
            "moreArticles":"More articles on this category",
            "moreArticlesOfAuthor":"All articles on this author",
            "tripgoalsDesc":"Adventures & worth visiting.",
            "floridaDesc":"Interesting facts about the state.",
            "traveltipsDesc":"From arrival to departure",
            "caption":"Inspiration and tips for your vacation in florida.",
            "filterText":"Search for anything ..."
        },
        "formular":{
            "caption":"Request now!",
            "subtitle":"Request now to rent your dream villa.",
            "errorMsg":"Error",
            "successMsg":"Thank you",
            "arrival":"Arrival",
            "departure":"Departure",
            "name":"First name and last name",
            "mail":"Mail",
            "phone":"Phone",
            "dsgvoText":"I have read the notes on privacy and accept them.",
            "dsgvoLinktext":"privacy policy",
            "submit":"Submit request"
        },
        "newsletterFormular":{
            "caption":"Get more via mail",
            "subtitle":"Get travel trips by our newsletter"
        },
        "contact":{
            "pageTitle":"Contact",
            "caption":"Get in touch.",
            "errorMsg":"Error",
            "successMsg":"Thank you.",
            "salutation":"Salutation",
            "name":"Full name",
            "mail":"Mail",
            "phone":"Phone",
            "msg":"Message",
            "packageText":"Do you want to receive the free florida package?",
            "street":"Street & Number",
            "zipAndCity":"Zip & City",
            "country":"Country",
            "dsgvoText":"I have read the notes on privacy and accept them.",
            "dsgvoLinktext":"privacy policy",
            "submit":"Submit form",
            "mr": "Mr.",
            "mrs": "Mrs.",
            "germany": "Germany",
        },
        "dankeAnfrage":{
            "pageTitle":"Thank you for your request",
            "caption":"Thank you for your interest regarding our villa",
            "text":"We will contact you immediately. ",
        },
        "dankeKontakt":{
            "pageTitle":"Thank you for contacting us",
            "caption":"Thank you for getting in contact with us",
            "text":"We will contact you immediately",
        },
        "dankeNewsletter":{
            "pageTitle":"Thank you for your interest",
            "caption":"Thank you for your subscription to our Newsletter",
            "text":"Please take a look at the inbox of your e-mail account and if necessary the spam folder and confirm your registration by activating your account.",
        },
        "dankeSubscription":{
            "pageTitle":"Thank you for your interest",
            "caption":"Thank you for your subscription!",
            "text":"We will inform you regularly about our villas and upcoming information about Florida.",
        },
        "errorPage":{
            "pageTitle":"404",
            "caption":"404 - Not found",
            "text":"Please go back."
        },
        "imprint":{
            "pageTitle":"Imprint",
            "caption":"Imprint",
        },
        "dataPrivacy":{
            "pageTitle":"Data privacy",
            "caption":"Data privacy",
        },
    },
    "de":{
        "general":{
            "browserTitle":"Villen & Ferienhäuser suchen in Cape Coral, Florida",
            "villas":"Villen",
            "wishlist":"Merkliste",
            "blog":"Blog",
            "contact":"Kontakt",
            "faq":"FAQ",
            "imprint":"Impressum",
            "dataPrivacy":"Datenschutz",
            "goBack":"Zurück",
            "toHome":"Zur Startseite",
        },
        "dashboard":{
            "teaserCta":"Zeige alle Villen"
        },
        "villas":{
            "pageCaption":"Finden Sie genau das, wonach Sie suchen.",
            "baths":"Bäder",
            "bedrooms":"Schlafzimmer",
            "filterText":"Suche hier nach einem Begriff...",
            "weeklyRent":"Wochenmiete",
            "perWeek":"pro Woche",
            "show":"Zeige",
            "of":"von",
            "to":"bis",
            "availableVillas":"verfügbaren Villen",
            "more":"Erfahre mehr",
            "addTo":"Merken",
            "removeFrom":"Entmerken",
            "yourWishlist":"Ihre Merkliste",
            "description":"Beschreibung",
            "location":"Lage",
            "video":"Video",
            "showAll":"Zeige alle Bilder",
            "showLess":"Weniger Bilder",
            "pool": "Pool",
            "poolheater":"Poolheizung",
            "whirlpool":"Whirlpool",
            "grill":"Grill",
            "seaAccess":"Golf Zugang",
            "privatePier": "Boots Dock",
            "facilities": "Ausstattung",
            "moreFilters": "Mehr Filter",
            "emptyWishlist": "Sie haben sich leider noch keine Villa gemerkt.",
        },
        "blog":{
            "tripgoals":"Ausflugsziele",
            "florida":"Über Florida",
            "traveltips":"Reisetipps",
            "readArticleCta":"Zum Blogeintrag",
            "moreArticles":"Weitere Artikel in dieser Kategorie",
            "moreArticlesOfAuthor":"Alle Artikel von diesem Autor",
            "tripgoalsDesc":"Abenteuer & Sehenswertes.",
            "floridaDesc":"Wissenswertes über den Staat.",
            "traveltipsDesc":"Von der Anreise bis zur Abreise.",
            "caption":"Inspiration, Tipps & Tricks für Ihren Urlaub in Florida.",
            "filterText":"Suche nach einem Begriff..."
        },
        "formular":{
            "caption":"Jetzt anfragen!",
            "subtitle":"Fragen Sie jetzt Ihre Wunschvilla zur Miete an.",
            "errorMsg":"Ihre Informationen wurden nicht gesendet. Bitte versuchen Sie es später noch einmal.",
            "successMsg":"Vielen Dank für Ihre Anfrage, wir melden uns schnellstmöglich bei Ihnen.",
            "arrival":"Anreise",
            "departure":"Abreise",
            "name":"Vor- und Nachname",
            "mail":"Mail",
            "phone":"Telefonnummer",
            "dsgvoText":"Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer",
            "dsgvoLinktext":"Datenschutzerklärung",
            "submit":"Buchung anfragen"
        },
        "newsletterFormular":{
            "caption":"Mehr per Mail!",
            "subtitle":"In unserem Newsletter erhalten Sie Urlaubstipps!"
        },
        "contact":{
            "pageTitle":"Kontakt",
            "caption":"Kontakt aufnehmen.",
            "errorMsg":"Ihre Informationen wurden nicht gesendet. Bitte versuchen Sie es später noch einmal.",
            "successMsg":"Vielen Dank für Ihre Anfrage, wir melden uns schnellstmöglich bei Ihnen.",
            "salutation":"Anrede",
            "name":"Vor- und Nachname",
            "mail":"Mail",
            "phone":"Telefonnummer",
            "msg":"Nachricht",
            "packageText":"Möchten Sie das kostenlose Florida Paket zugesendet bekommen?",
            "street":"Straße & Hausnummer",
            "zipAndCity":"PLZ & Ort",
            "country":"Land",
            "dsgvoText":"Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer",
            "dsgvoLinktext":"Datenschutzerklärung",
            "submit":"Formular abschicken",
            "mr": "Herrn",
            "mrs": "Frau",
            "germany": "Deutschland",
        },
        "dankeAnfrage":{
            "pageTitle":"Danke für Ihre Anfrage",
            "caption":"Danke für Ihr Interesse an unserer Villa",
            "text":"Wir werden uns umgehend mit Ihnen in Verbindung setzen"
        },
        "dankeKontakt":{
            "pageTitle":"Danke für Ihre Kontaktaufnahme",
            "caption":"Danke für Ihre Kontaktaufnahme mit Villas of Paradise",
            "text":"Wir werden uns umgehend mit Ihnen in Verbindung setzen"
        },
        "dankeNewsletter":{
            "pageTitle":"Danke für Ihr Interesse",
            "caption":"Vielen Dank für Ihre Newsletter Anmeldung",
            "text":"Prüfen Sie bitte den Posteingang Ihres E-Mail Postfachs und gegebenenfalls den Spam-Ordner und bestätigen Sie Ihre Anmeldung durch Aktivieren Ihres Kontos."
        },
        "dankeSubscription":{
            "pageTitle":"Danke für Ihr Interesse",
            "caption":"Vielen Dank für Ihre Newsletter Anmeldung",
            "text":"Wir werden Sie regelmäßig über unsere Villen und Information rund um Florida informieren.",
        },
        "errorPage":{
            "pageTitle":"404",
            "caption":"404 - Nicht gefunden",
            "text":"Leider konnten wir nichts finden."
        },
        "imprint":{
            "pageTitle":"Impressum",
            "caption":"Impressum",
        },
        "dataPrivacy":{
            "pageTitle":"Datenschutz",
            "caption":"Datenschutz",
        },
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
