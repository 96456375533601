import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import Placeholder from '../../../components/common/Placeholder';
import Button from '../../../components/common/Button';
import {tools} from '../../../tools/styles';

const useStyles = createUseStyles({
    teaserArea: {
        backgroundColor: 'white',
        boxShadow: tools.boxShadow,
        borderRadius: 5,
        padding: '50px 70px',
        marginLeft: -200,
        marginRight: 200,
        position: 'relative',
    },
    teaserAreaWrapper: {
        minHeight: 700,
        display: 'flex',
        alignItems: 'center',
    },
    siegel: {
        position: 'absolute',
        top: -40,
        right: -40,
        height: 110,
        maxWidth: 'auto',
    },
    teaserAreaMobile: {
        backgroundColor: 'white',
        boxShadow: tools.boxShadow,
        borderRadius: 5,
        position: 'relative',
    },
    textAreaMobile: {
        padding: 15,
    },
});

const MidTeaser = (props) => {

    const classes = useStyles();
    const { t } = useTranslation(['dashboard']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    return <>
        <Row className={'d-none d-md-flex'}>
            <Col md={{span: 5, offset: 1}}>
                <Placeholder className={props.classes.figureImage}
                             image={'/images/Villa-Rechteck.jpg'} h={700}/>
            </Col>
            <Col md={6} className={classes.teaserAreaWrapper}>
                <div className={classes.teaserArea}>
                    <img src="/images/siegel.png" alt="" className={classes.siegel}/>
                    <h2 className={props.classes.h3}>
                        {props.content.fields.midTeaserCaption}
                    </h2>
                    <p className={props.classes.p}>
                        {props.content.fields.midTeaserText}
                    </p>
                    <Link to={`/${language}/villas`}>
                        <Button>{t('teaserCta')}</Button>
                    </Link>
                </div>
            </Col>
        </Row>
        <Row className={'d-sm-flex d-md-none'}>
            <Col>
                <div className={classes.teaserAreaMobile}>
                    <Placeholder className={props.classes.figureImage}
                                 image={'/images/Villa-Rechteck.jpg'} h={300}/>

                    <div className={classes.textAreaMobile}>
                        <h2 className={props.classes.h3}>
                            {props.content.fields.midTeaserCaption}
                        </h2>
                        <p className={props.classes.p}>
                            {props.content.fields.midTeaserText}
                        </p>
                        <Link to={`/${language}/villas`}>
                            <Button variant={'secondary'}>Zeige alle Villen</Button>
                        </Link>
                    </div>
                </div>
            </Col>
        </Row>
    </>;
};

MidTeaser.defaultProps = {
    content: {},
    language: '',
};

export default MidTeaser;
