import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';

import VillenList from '../../components/villen/VillenList';
import VillenMap from '../../components/villen/VillenMap';
import {entmerkeVilla, fetchVillen, merkeVilla} from '../../actions';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import {mixins} from '../../tools/styles';
import {getLanguageOfLocation} from '../../tools/tools';

const useStyles = createUseStyles({
    h1: {
        ...mixins.h1,
    },
    unboxed: {
        ...mixins.unboxed,
    }
});

const Merkliste = (props) => {

    const classes = useStyles();
    const {t} = useTranslation(['villas', 'general']);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchVillen(language);
    }, [props.location]);

    const [villen, setVillen] = useState(props.villen || []);

    const [merkliste, setMerkliste] = useState({});
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);
    const toggleMerkliste = (slug) => {
        if (!!merkliste[slug]) {
            props.entmerkeVilla(slug);
        } else {
            props.merkeVilla(slug);
        }
    };

    useEffect(() => {
        if (props.villen) {
            const gemerkteVillen = [];
            [...props.villen].map((item, index) => {
                if (!!merkliste[item.fields.slug]) {
                    gemerkteVillen.push(item);
                }
            });

            setVillen(gemerkteVillen);
        }
    }, [props.villen, merkliste]);

    return <>
        <Helmet>
            <title>{t('general:browserTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <br/>
        <Container>
            <Row>
                <Col>
                    <h1 className={classes.h1}>
                        {t('yourWishlist')}
                    </h1>
                </Col>
            </Row>
            <br/>
            {villen && villen.length > 0 ?
                <><Row>
                    <Col>
                        <VillenMap villen={villen} apiKey={'AIzaSyBhfS3_jCKbw_ZnYpT3SlmO4FMV4wpf7cY'}
                                   language={props.language.active}/>
                    </Col>
                </Row>
                    <br/><br/>
                    <Row>
                        <Col>
                            <VillenList
                                villen={villen}
                                merkliste={merkliste} toggleMerkliste={toggleMerkliste}
                                language={props.language.active}/>
                            <br/>
                        </Col>
                    </Row>
                    <br/>
                </> : <h2 className={classes.unboxed}>{t('emptyWishlist')}</h2>
            }
            <br/>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        villen: state.villen,
        merkliste: state.merkliste,
        language: state.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVillen: (language) => {
            dispatch(fetchVillen(language));
        },
        merkeVilla: (slug) => {
            dispatch(merkeVilla(slug));
        },
        entmerkeVilla: (slug) => {
            dispatch(entmerkeVilla(slug));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Merkliste);
