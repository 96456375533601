import {combineReducers} from 'redux';

import villen from './villen';
import filter from './filter';
import meta from './meta';
import authors from './authors';
import blog from './blog';
import blogFilter from './blogFilter';
import faq from './faq';
import merkliste from './merkliste';
import language from './language';

export default combineReducers({
    villen,
    filter,
    meta,
    authors,
    blog,
    blogFilter,
    faq,
    merkliste,
    language,
});
