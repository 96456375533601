import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import {FaCalendarAlt, FaUser} from 'react-icons/fa';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';

import {fetchBlog} from '../../../actions';
import BackLink from '../../../components/common/BackLink';
import Header from '../../Dashboard/components/Header';
import {breakpoints, colors, fonts, mixins, tools} from '../../../tools/styles';
import NewsletterFormular from './NewsletterFormular';
import Item from '../Item';
import CustomMarkdown from '../../../components/common/CustomMarkdown';
import SocialShare from '../../../components/common/SocialShare';
import {BlogCategories} from '../../../enums/blogCategories';
import {getLanguageOfLocation} from '../../../tools/tools';
import BlogCategoryIcon from '../../../components/common/BlogCategoryIcon';
import HeightSpacer from '../../../components/common/HeightSpacer';

const useStyles = createUseStyles({
    h3: {
        ...mixins.h3,
    },
    h4: {
        ...mixins.h4,
    },
    caption: {
        ...mixins.caption,
    },
    unboxed: {
        ...mixins.unboxed,
    },
    p: {
        ...mixins.p,
    },
    formular: {
        boxShadow: tools.boxShadow,
        padding: 30,
        backgroundColor: 'white',
        borderRadius: tools.borderRadius,
        position: 'sticky',
        marginTop: 0,
        top: 110,
        [`@media (max-width: ${breakpoints.mdMax}px)`]: {
            position: 'relative',
            marginTop: 30,
            top: 0,
        },
    },
    metaIcon: {
        color: colors.secondary,
        marginRight: 20,
        lineHeight: '20px',
    },
    metaText: {
        color: colors.primary,
        lineHeight: '20px',
        fontFamily: fonts.secondary,
        fontSize: 20,
    },
    metaCol: {
        paddingTop: 25,
        paddingBottom: 25,
        [`@media (max-width: ${breakpoints.mdMax}px)`]: {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    metaLink: {
        textDecoration: 'none!important'
    }
});

const BlockDetail = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['blog']);

    const [blogId, setBlogId] = useState(0);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchBlog(language);

        try {
            const {id} = queryString.parse(props.location.search, {ignoreQueryPrefix: true, parseNumbers: true});
            setBlogId(id);
        } catch (e) {
        }
    }, [props.location]);

    const [article, setArticle] = useState({});
    const [categoryArticles, setCategoryArticles] = useState([]);
    const [category, setCategory] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [format, setFormat] = useState('D MMM YYYY');

    useEffect(() => {
        let categoryTmp = '';
        props.blog.map((item) => {
            if (item.fields.id === blogId) {
                const categories = Object.values(BlogCategories);
                if (categories.includes(item.fields.category)) {
                    categoryTmp = item.fields.category;
                    setCategory(categoryTmp);
                }
                setArticle(item);

                if (categoryTmp) {
                    switch (categoryTmp) {
                        case BlogCategories.TRIPGOALS:
                            setCategoryName(t('tripgoals'));
                            break;
                        case BlogCategories.FLORIDA:
                            setCategoryName(t('florida'));
                            break;
                        case BlogCategories.TRAVELTIPS:
                            setCategoryName(t('traveltips'));
                            break;
                    }
                }
            }
        });

        const newCategoryArticles = [];
        props.blog.map((item) => {
            if (item.fields.id !== blogId) {
                if (item.fields.category === categoryTmp) {
                    newCategoryArticles.push(item);
                }
            }
        });

        setCategoryArticles(newCategoryArticles);

    }, [props.blog, blogId]);

    useEffect(() => {
        if (props.language.active === 'de') {
            setFormat('DD.MM.YYYY');
        } else {
            setFormat('D MMM YYYY');
        }
    }, [props.language]);

    return <>
        <Helmet>
            <title>{article.fields && `${article.fields.titel} | Villas of Paradise`}</title>
            {article.fields && <meta name="title" content={`${article.fields.metaTitel || article.fields.titel}`}/>}
            {article.fields &&
            <meta name="description" content={`${article.fields.metaDescription || article.fields.text}`}/>}
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        {article.fields && <Header
            image={`${article.fields.headerImage.fields.file.url}?w=2000`}
            h={500}/>}
        <br/>
        <Container>
            <Row>
                <Col>
                    <BackLink/>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    {article.fields && <>
                        <Row>
                            <Col lg={8}>
                                <h1 className={classes.h3}>
                                    {article.fields.titel}
                                </h1>
                            </Col>
                            <Col lg={4}>
                                <SocialShare/>
                                <div className={'d-xs-flex d-sm-none'}>
                                    <br/><br/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className={'d-xs-flex d-md-none'}>
                                <HeightSpacer/>
                                <HeightSpacer/>
                            </div>
                            <Col md="4" className={`${classes.metaCol} d-none d-md-flex`}>
                                <BlogCategoryIcon category={category} size={20}
                                                  className={classes.metaIcon}/> <span
                                className={classes.metaText}>{categoryName}</span>
                            </Col>
                            {article.fields.autor && <Col md="4" className={classes.metaCol}>
                                <Link to={`/${props.language.active}/authors/${article.fields.autor.fields.slug}`} className={classes.metaLink}><FaUser
                                    className={classes.metaIcon}/> <span
                                    className={classes.metaText}>{article.fields.autor.fields.name}</span></Link>
                            </Col>}
                            <Col md="4" className={classes.metaCol}>
                                <FaCalendarAlt className={classes.metaIcon}/> <span className={classes.metaText}><Moment
                                format={format} withTitle>{article.fields.date}</Moment></span>
                                <div className={'d-xs-flex d-md-none'}>
                                    <HeightSpacer/>
                                    <HeightSpacer/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomMarkdown className={classes.unboxed} source={article.fields.text}/>
                            </Col>
                        </Row>
                    </>}
                    {article.fields && categoryArticles.length > 0 &&
                    <>
                        <br/><br/><br/>
                        <Row>
                            <Col>
                                <h2 className={classes.h4}>
                                    {t('moreArticles')}
                                </h2>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            {categoryArticles.map((item, index) => {
                                return <Col sm={6} key={index} style={{marginBottom: 30}}>
                                    <Item item={item} category={article.fields.category}
                                          language={props.language.active}/>
                                </Col>;
                            })}
                        </Row>
                    </>
                    }
                </Col>
                <Col>
                    <NewsletterFormular classes={classes} language={props.language.active}/>
                </Col>
            </Row>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        blog: state.blog,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchBlog: (language) => {
            dispatch(fetchBlog(language));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockDetail);
