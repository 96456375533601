export const enhanceAsset = (image, assets) => {
    let found = false;
    if (!found) {
        assets.Asset.map(asset => {
            if (image && !found) {
                if (image.sys.id === asset.sys.id) {
                    image = asset;
                    found = true;
                }
            }
        });
    }
    return image;
};

export const enhanceAssets = (imageArray, assets) => {
    const newImages = [];
    imageArray.map(image => {
        newImages.push(enhanceAsset(image, assets));
    });
    return newImages;
};
