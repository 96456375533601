import React, {useState} from 'react';
import {GoogleMap, InfoWindow, Marker, useLoadScript} from '@react-google-maps/api';
import {FaMapMarkerAlt} from 'react-icons/fa';
import {createUseStyles} from 'react-jss';

import CustomSpinner from '../../common/CustomSpinner';
import {tools, mixins} from '../../../tools/styles';
import VillaAddress from '../../common/VillaAddress';

const useStyles = createUseStyles({
    h4: {
        ...mixins.h4,
    },
    p: {
        ...mixins.p,
    },
    frame: {
        borderRadius: tools.borderRadius,
    },
});

const DetailMap = (props) => {
    const classes = useStyles();
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyBhfS3_jCKbw_ZnYpT3SlmO4FMV4wpf7cY',
    });

    const [showInfo, setShowInfo] = useState(false);
    const handler = () => {
        setShowInfo(!showInfo);
    };

    const renderMap = () => {
        return <GoogleMap
            mapContainerStyle={{
                height: '400px',
                width: '100%',
            }}
            zoom={13}
            center={{
                lat: props.villa.fields.coordinates.lat,
                lng: props.villa.fields.coordinates.lon,
            }}
            className={classes.frame}>
            {showInfo && <InfoWindow
                position={{lat: props.villa.fields.coordinates.lat, lng: props.villa.fields.coordinates.lon}}
                onCloseClick={handler}>
                <div style={{background: `white`, padding: 15,}}>
                    <div>
                        <h5 className={classes.h4}>{props.villa.fields.caption}</h5>
                        <div className={classes.p}>
                            <FaMapMarkerAlt/> <VillaAddress address={props.villa.fields.address}/>
                        </div>
                    </div>
                </div>
            </InfoWindow>}

            <Marker
                position={{
                    lat: props.villa.fields.coordinates.lat,
                    lng: props.villa.fields.coordinates.lon,
                }}
                title={props.villa.fields.caption}
                onClick={handler}
            />
        </GoogleMap>;
    };

    if (loadError) {
        return <div className={classes.p}>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <CustomSpinner/>;
};

DetailMap.defaultProps = {};

export default DetailMap;
