import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';
import {scroller} from 'react-scroll';

import Filter from './Filter';
import VillenList from '../../components/villen/VillenList';
import VillenMap from '../../components/villen/VillenMap';
import {entmerkeVilla, fetchVillen, merkeVilla, setPage} from '../../actions';
import TopNavBar from '../../components/common/TopNavBar';
import HeadSpace from '../../components/common/HeadSpace';
import {mixins} from '../../tools/styles';
import {getLanguageOfLocation} from '../../tools/tools';
import FilterRow from './FilterRow';
import CustomPagination from '../../components/common/CustomPagination';

const useStyles = createUseStyles({
    h1: {
        ...mixins.h1,
    },
    unboxed: {
        ...mixins.unboxed,
    }
});

const Villen = (props) => {
    const classes = useStyles();
    const {t} = useTranslation(['villas', 'general']);

    useEffect(() => {
        const language = getLanguageOfLocation(props.location) || props.language.active;
        props.fetchVillen(language);
    }, [props.location]);

    const [items, setItems] = useState(props.items || []);
    const [storeFilter, setStoreFilter] = useState(props.filter || {});
    const [minMax, setMinMax] = useState({});

    const [merkliste, setMerkliste] = useState({});
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);
    const toggleMerkliste = (slug) => {
        if (!!merkliste[slug]) {
            props.entmerkeVilla(slug);
        } else {
            props.merkeVilla(slug);
        }
    };

    const sortFunction = (a, b) => {
        return b.fields.isTopVilla - a.fields.isTopVilla;
    };

    useEffect(() => {
        items.sort(sortFunction);
    }, []);

    useEffect(() => {
        if (props.filter) {
            setStoreFilter(props.filter);
        }
    }, [props.filter]);

    useEffect(() => {
        if (props.items.length > 0) {
            const newMinMax = {...minMax};
            if (!newMinMax.baths) {
                newMinMax.baths = {min: 3, max: 3};
            }
            if (!newMinMax.beds) {
                newMinMax.beds = {min: 3, max: 3};
            }
            props.items.map((itm, index) => {
                if (itm.fields.bathsAmount > newMinMax.baths.max) {
                    newMinMax.baths.max = itm.fields.bathsAmount;
                }
                if (itm.fields.bathsAmount < newMinMax.baths.min) {
                    newMinMax.baths.min = itm.fields.bathsAmount;
                }
                if (itm.fields.bedsAmount > newMinMax.beds.max) {
                    newMinMax.beds.max = itm.fields.bedsAmount;
                }
                if (itm.fields.bedsAmount < newMinMax.beds.min) {
                    newMinMax.beds.min = itm.fields.bedsAmount;
                }
            });
            setMinMax(newMinMax);
        }
    }, [props.items]);

    useEffect(() => {
        if (props.items) {
            if (!storeFilter) {
                setItems(props.items);
                return;
            }
            if (storeFilter) {
                const filteredVillen = [];

                props.items.map((itm) => {
                    let yes = true;
                    if (storeFilter.filter.rent && storeFilter.filter.rent.length === 2) {
                        if (
                            itm.fields.weeklyCosts < storeFilter.filter.rent[0] ||
                            itm.fields.weeklyCosts > storeFilter.filter.rent[1]
                        ) {
                            yes = false;
                        }
                    }
                    if (storeFilter.filter.beds && storeFilter.filter.beds.length === 2) {
                        if (
                            itm.fields.bedsAmount < storeFilter.filter.beds[0] ||
                            itm.fields.bedsAmount > storeFilter.filter.beds[1]
                        ) {
                            yes = false;
                        }
                    }
                    if (storeFilter.filter.baths && storeFilter.filter.baths.length === 2) {
                        if (
                            itm.fields.bathsAmount < storeFilter.filter.baths[0] ||
                            itm.fields.bathsAmount > storeFilter.filter.baths[1]
                        ) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.text) {
                        if (
                            itm.fields.caption.toLowerCase().search(storeFilter.filter.text.trim().toLowerCase()) === -1
                            &&
                            itm.fields.text.toLowerCase().search(storeFilter.filter.text.trim().toLowerCase()) === -1
                        ) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.hasPoolHeater) {
                        if (!itm.fields.poolsAmount > 0) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.hasWhirlpool) {
                        if (!itm.fields.hasWhirlpool) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.hasGrill) {
                        if (!itm.fields.hasGrill) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.hasGulfAccess) {
                        if (!itm.fields.hasGulfAccess) {
                            yes = false;
                        }
                    }

                    if (storeFilter.filter.hasBoatLanding) {
                        if (!itm.fields.hasBoatLanding) {
                            yes = false;
                        }
                    }

                    if (yes) {
                        if (!itm.fields.isTopVilla) {
                            itm.fields.isTopVilla = false;
                        }

                        filteredVillen.push(itm);
                    }
                });
                filteredVillen.sort(sortFunction);
                setItems(filteredVillen);
            }
        }
    }, [storeFilter, props.items]);

    const paginationOnClickHandler = (page) => {
        scroller.scrollTo('villenListScrollElement', {
            duration: 500,
            delay: 100,
            offset: -113,
            smooth: true,
        });
        props.setPage(page);
    };

    return <>
        <Helmet>
            <title>{t('general:browserTitle')} | Villas of Paradise</title>
            <meta name="robots" content="index,dofollow"/>
            <meta content="#E65759" name="theme-color"/>
        </Helmet>
        <TopNavBar/>
        <HeadSpace/>
        <br/>
        <Container>
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col>
                            <h1 className={classes.h1}>
                                {t('pageCaption')}
                            </h1>
                        </Col>
                    </Row>
                    <br/>
                    <Filter filter={storeFilter.filter} minMax={minMax} language={props.language.active}/>
                </Col>
                <Col lg={6} style={{marginBottom: 30}} className={'d-none d-lg-block'}>
                    <VillenMap villen={items} apiKey={'AIzaSyBhfS3_jCKbw_ZnYpT3SlmO4FMV4wpf7cY'}
                               language={props.language.active}/>
                </Col>
            </Row>
            <br/>
            <br/>
            <FilterRow filter={storeFilter.filter} minMax={minMax} language={props.language.active}/>
            <br/><br/>
            <Row>
                <Col>
                    <div className={classes.unboxed}>
                        {t('show')} <strong>{items.length}</strong> {t('of')}
                        <strong> {props.items.length}</strong> {t('availableVillas')}
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <VillenList
                        name="test"
                        villen={[...items].splice((props.filter.page - 1) * props.filter.items_per_page, props.filter.items_per_page)}
                        merkliste={merkliste} toggleMerkliste={toggleMerkliste} language={props.language.active}/>
                    <br/>
                    <CustomPagination max={Math.ceil(items.length / props.filter.items_per_page)}
                                      page={props.filter.page} onClick={paginationOnClickHandler}/>
                </Col>
            </Row>
            <br/>
        </Container>
    </>;
};

const mapStateToProps = (state) => {
    return {
        items: state.villen,
        filter: state.filter,
        merkliste: state.merkliste,
        language: state.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVillen: (language) => {
            dispatch(fetchVillen(language));
        },
        merkeVilla: (slug) => {
            dispatch(merkeVilla(slug));
        },
        entmerkeVilla: (slug) => {
            dispatch(entmerkeVilla(slug));
        },
        setPage: (page) => {
            dispatch(setPage(page));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Villen);
