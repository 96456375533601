import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const VillaPropsOneline = (props) => {

    const { t } = useTranslation(['villas']);

    const [villa, setVilla] = useState(props.villa);
    useEffect(() => {
        setVilla(props.villa);
    }, [props.villa]);

    return <>{villa.fields.bathsAmount} {t('baths')} / {villa.fields.bedsAmount} {t('bedrooms')} / {villa.fields.propertyType}</>;
};

VillaPropsOneline.defaultProps = {
    villa: {},
};

export default VillaPropsOneline;
