import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {createUseStyles} from 'react-jss';
import {Slide} from 'react-slideshow-image';
import {isMobileOnly} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {breakpoints, colors, fonts, mixins, tools} from '../../../tools/styles';
import Placeholder from '../../common/Placeholder';
import Button from '../../common/Button';
import CustomMarkdown from '../../common/CustomMarkdown';
import VillenMeta from '../VillenMeta';
import MerkToggle from '../../common/MerkToggle';
import HeightSpacer from '../../common/HeightSpacer';

const useStyles = createUseStyles({
    h1: {
        ...mixins.h1,
    },
    h4: {
        ...mixins.h4,
    },
    caption: {
        ...mixins.caption,
    },
    p: {
        ...mixins.p,
    },
    row: {
        boxShadow: tools.boxShadow,
        borderRadius: tools.borderRadius,
        marginBottom: 40,
    },
    box: {
        padding: 30,
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            padding: 15,
        }
    },
    merken: {
        paddingLeft: 30,
        [`@media (min-width: ${breakpoints.smMin}px) and (max-width: ${breakpoints.smMax}px)`]: {
            paddingLeft: 20,
        },
        [`@media (max-width: ${breakpoints.xsMax}px)`]: {
            paddingLeft: 8,
        },
    },
    topVilla: {
        position: 'relative',
        marginLeft: -170,
        marginTop: -300,
        width: '100%',
        fontFamily: fonts.secondary,
        backgroundColor: colors.secondary,
        textAlign: 'center',
        color: 'white',
        transform: 'rotate(-40deg)',
        padding: 10,
    },
});

const VillenListItem = (props) => {

    const classes = useStyles();
    const {t} = useTranslation(['villas']);

    const [language, setLanguage] = useState(props.language);
    useEffect(() => {
        setLanguage(props.language);
    }, [props.language]);

    const [merkliste, setMerkliste] = useState(props.merkliste);
    useEffect(() => {
        setMerkliste(props.merkliste);
    }, [props.merkliste]);

    const [item, setItem] = useState(props.item);
    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    const sliderProperties = {
        duration: 10000,
        transitionDuration: 500,
        infinite: true,
        indicators: false,
        arrows: true,
    };

    return <Row noGutters className={classes.row}>
        <Col xs={12} md={4} className={classes.col}>
            <Slide {...sliderProperties}>
                <div className="each-slide">
                    <Placeholder placeholder={`/images/400x300${item.fields.isTopVilla ? '_topvilla' : ''}.png`}
                                 image={`${item.fields.images[0].fields.file.url}?h=400&fit=thumb`}/>
                </div>
                {item.fields.images[1] && <div className="each-slide">
                    <Placeholder placeholder={`/images/400x300${item.fields.isTopVilla ? '_topvilla' : ''}.png`}
                                 image={`${item.fields.images[1].fields.file.url}?h=400&fit=thumb`}/>
                </div>}
                {item.fields.images[2] && <div className="each-slide">
                    <Placeholder placeholder={`/images/400x300${item.fields.isTopVilla ? '_topvilla' : ''}.png`}
                                 image={`${item.fields.images[2].fields.file.url}?h=400&fit=thumb`}/>
                </div>}
            </Slide>
        </Col>
        <Col xs={12} md={8}>
            <div className={classes.box}>
                <h2 className={classes.h4}><span style={{zIndex: 100}}>{item.fields.caption}</span> {item.fields.isTopVilla && <span><img
                    src="/images/siegel.png" alt="Top Villa" style={{height: '2em', width: 'auto',}}/></span>}</h2>
                <HeightSpacer/>
                <VillenMeta villa={item} language={language}/>
                <HeightSpacer/>
                <CustomMarkdown className={classes.p}
                                source={{...item.fields}.text.split(' ').slice(0, isMobileOnly ? 21 : 39).join(' ') + ' ...'}/>
                <HeightSpacer/>

                <Link to={`/${language}/villas/${item.fields.slug}`}>
                    <Button variant={'secondary'}>{t('more')}</Button>
                </Link> <span className={classes.merken}><MerkToggle
                isGemerkt={!!merkliste[item.fields.slug]}
                onClick={() => props.toggleMerkliste(item.fields.slug)}/></span>
            </div>
        </Col>
    </Row>;
};

VillenListItem.defaultProps = {
    item: {},
    merkliste: {},
    language: '',
    toggleMerkliste: () => {
    },
};

export default VillenListItem;
