import React from 'react';
import {FaMapPin, FaCompass, FaSuitcase, FaRoad} from 'react-icons/fa';

import {BlogCategories} from '../../enums/blogCategories';

const BlogCategoryIcon = (props) => {

    switch (props.category) {
        case BlogCategories.TRIPGOALS:
            return <FaCompass className={props.className} size={props.size} style={{display: props.display}}/>;

        case BlogCategories.FLORIDA:
            return <FaMapPin className={props.className} size={props.size} style={{display: props.display}}/>;

        case BlogCategories.TRAVELTIPS:
            return <FaSuitcase className={props.className} size={props.size} style={{display: props.display}}/>;

        default:
            return <FaRoad className={props.className} size={props.size} style={{display: props.display}}/>;
    }

};
BlogCategoryIcon.defaultProps = {
    size: 20,
    display: 'inline',
    className: '',
};

export default BlogCategoryIcon;
